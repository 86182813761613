import React, { useEffect, useState } from 'react'
import cookie from 'react-cookies'
import {
  capitalizeFirstLetterFrom,
  effectiveLocationWithAllowedLocation,
  formatMoney
} from '../../helpers/utils'
import { Table } from 'react-bootstrap'

const RefundDetailsTab = ({ activity = {}, transactionUuid }) => {
  const [taxSettings, setTaxSettings] = useState(cookie.load('tax_settings'))

  const getFormattedDate = (date) => {
    return date ? new Date(date).toLocaleString() : '-' 
  }

  const getWeight = (weight = 0) => {
    return Number(weight).toFixed(2) === '0.00' ? 'N/A' : Number(weight).toFixed(2)
  }

  const getRowSubtotal = (extendedPrice = 0, discountedPrice = 0) => {
    return discountedPrice ? extendedPrice - discountedPrice : extendedPrice
  }

  const handleRefundType = () => {
    switch (activity?.refund_type) {
      case 'refund':
        return 'rechazo total'
      case 'partial_refund':
        return 'rechazo parcial'
      default:
        return 'devolución'
    }
  }

  const isDelivery = () => {
    return activity?.refund_type && activity?.refund_type !== 'salesman_refund'
  }

  const customFieldsArray = Array.isArray(activity.custom_fields) ? activity.custom_fields : [];

  const tableRows = customFieldsArray.length
    ? customFieldsArray.map(({ label, value }) => ({
        label: label || '-',
        value: value || '-',
      }))
    : [
        { label: 'Fecha de documento', value: getFormattedDate(activity.created_at) },
        { label: isDelivery() ? 'Número de rechazo' : 'Número de devolución', value: activity.refund_db_ref || '-' },
        { label: 'Fecha de ejecución', value: getFormattedDate(activity.created_date || activity.collected_at) },
        { label: 'Vendedor', value: activity.salesman_name || '-' },
        isDelivery() && { label: 'Tipo de cliente', value: capitalizeFirstLetterFrom(activity.client_payment_type) || '-' },
        handleRefundType() !== 'rechazo parcial' && { label: `Motivo de ${isDelivery() ? 'rechazo' : 'devolución'}`, value: activity.motive || '-' },
        { label: 'Número de factura', value: activity.invoice_number || '-' },
        !isDelivery() && { label: 'Comentarios generales', value: activity.comments || '-' },
        { label: 'Dentro de geocerca', value: effectiveLocationWithAllowedLocation(activity.effective_location, activity.has_allowed_location) || '-' },
      ].filter(Boolean);

  const extraColumns = new Set();
  const processedDetails = activity.details?.map((product) => {
    let customFieldValues = {};
  
    try {
      let customFields = product.custom_fields_item;
      if (typeof customFields === "string" && customFields.trim() !== "") {
        customFields = JSON.parse(customFields);
      }
      if (Array.isArray(customFields)) {
        customFields.forEach(({ label, value }) => {
          extraColumns.add(label);
          customFieldValues[label] = value ?? "-"; 
        });
      }
    } catch (error) {
      console.error("Error parsing custom_fields_item:", error);
    }
  
    return { ...product, customFieldValues };
  }) || [];


  return (
    <div className="container-fluid">
      <div className="row">
        {transactionUuid && (
          <>
            <div className="row show-product-header">
              <div className="col-sm-6">
                <h3 className="margin-text-subtitle">Detalles de {handleRefundType()}:</h3>
              </div>
            </div>
            <hr />
          </>
        )}

        <div>
          <div className="show-product-header-ubiqua-app show-header">
            <div>
              <div className="order-client-name">{activity.client_name}</div>
              <div className="order-client-db_ref">Código: {activity.client_db_ref}</div>
            </div>
          </div>
          <hr />

          <table cellSpacing="10">
            <tbody>
              {tableRows.map(({ label, value }, idx) => (
                <tr key={idx}>
                  <td>
                    <div className="mr-85 mb-10">
                      <b>{label}:</b>
                    </div>
                  </td>
                  <td>
                    <div className="mb-10">{value}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr />

          <div className="mr-85 mb-10">
            <b>{isDelivery() ? 'Productos' : 'Productos en devolución'}:</b>
          </div>

          <Table hover responsive>
            <thead className="table-header-gray">
              <tr>
                <th>CÓDIGO</th>
                <th>NOMBRE DEL PRODUCTO</th>
                {!isDelivery() && <th>N° LOTE</th>}
                {handleRefundType() !== 'rechazo total' && <th>MOTIVO {isDelivery() ? 'DE RECHAZO' : ''}</th>}
                {!isDelivery() && <th>PESO NETO</th>}
                <th>UNIDAD DE VENTA</th>
                <th>CANTIDAD</th>
                <th>PRECIO</th>
                <th>SUB-TOTAL</th>
                {[...extraColumns].map((col, index) => (
                  <th key={`col-${index}`}className="column-custom">{col}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {processedDetails.map((product, index) => (
                <tr key={index}>
                  <td>{product.product_db_ref}</td>
                  <td>{product.product_name}</td>
                  {!isDelivery() && <td>{product.batch_number || 'N/A'}</td>}
                  {handleRefundType() !== 'rechazo total' && <td>{product.motive || 'N/A'}</td>}
                  {!isDelivery() && <td>{getWeight(product.weight)}</td>}
                  <td>{product.price_unit}</td>
                  <td>{isDelivery() ? parseInt(product.quantity) : Number(product.quantity).toFixed(2)}</td>
                  <td>{formatMoney(Number(product.unit_price))}</td>
                  <td>{formatMoney(getRowSubtotal(product.extended_price, product.discount_price))}</td>
                  {[...extraColumns].map((col, idx) => (
                    <td key={`val-${index}-${idx}`}className="column-custom">{product.customFieldValues[col] || 'N/A'}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="totals-container">
            <div className="totals-card">
              <div className="totals-line">
                <div>
                  <b>SUB-TOTAL:</b>
                </div>
                <div>{formatMoney(activity.subtotal)}</div>
              </div>
              {taxSettings.tax_rate_2 && (
                <div className="totals-line">
                  <div>
                    <b>{taxSettings.tax_rate_2}:</b>
                  </div>
                  <div>{formatMoney(0)}</div>
                </div>
              )}
              <div className="totals-line">
                <div>
                  <b>{taxSettings.tax_rate}:</b>
                </div>
                <div>{formatMoney(activity.taxes)}</div>
              </div>
              <div className="totals-line">
                <div>
                  <h4>
                    <b>TOTAL:</b>
                  </h4>
                </div>
                <div>{formatMoney(activity.total)}</div>
              </div>
              {!isDelivery() && (
                <>
                  <hr />
                  <div className="totals-line">
                    <div>
                      <b>PESO TOTAL:</b>
                    </div>
                    <div>{getWeight(activity.total_weight)}</div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RefundDetailsTab
