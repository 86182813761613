import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import axios from 'axios'
import cookie from 'react-cookies'
import { RRule, SPANISH } from 'rrule'
import { fixDateString, getDay, getMonth } from '../../helpers/formatDate'
import ImageUploading from 'react-images-uploading'
import ImageModal from '../../components/image_modal/ImageModal'
import { replaceAccentedCharacters } from '../../helpers/utils'
import TaskDescription from './components/TaskDescription'
import TaskClientOrAttribute from './components/TaskClientOrAttribute'
import TaskScheduleConfig from './components/TaskScheduleConfig'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'

let countsCache = {
  brand: {},
  category: {}
}

const PlanimetryVerificationTask = ({
  setCloningTask,
  cloningTask,
  setFailedCreation,
  failedCreation,
  taskData,
  readyToCollect,
  setSaving,
  formDataCollector,
  isStep3Completed,
  setIsStep3Completed
}) => {
  const [description, setDescription] = useState('')
  const [instruction, setInstruction] = useState('')
  const [clientOrAttribute, setClientOrAttribute] = useState('')
  const [clients, setClients] = useState([])
  const [products, setProducts] = useState([])
  const [brand, setBrand] = useState('')
  const [category, setCategory] = useState('')
  const [productSelection, setProductSelection] = useState('')
  const [qualifiers, setQualifiers] = useState({
    qualifier_2: [],
    qualifier_3: [],
    qualifier_4: [],
    qualifier_5: []
  })
  const [qualifierA, setQualifierA] = useState([])
  const [qualifierB, setQualifierB] = useState([])
  const [qualifierC, setQualifierC] = useState([])
  const [users, setUsers] = useState([])
  const [userOptions, setUserOptions] = useState(null)
  const [userFilterChecked, setUserFilterChecked] = useState(false)
  const [newStartTime, setNewStartTime] = useState(null)
  const [newEndTime, setNewEndTime] = useState(null)
  const [recurrenceRule, setRecurrenceRule] = useState(null)
  const [productsBase, setProductsBase] = useState({})
  const [productCount, setProductCount] = useState('...')

  const [images, setImages] = useState([])
  const [showImage, setShowImage] = useState(false)
  const maxNumber = 1
  const maxMbFileSize = 5242880
  const newLinePoint = '\u2022'
  const pointWithSpace = `${newLinePoint} `

  const productSelectionType = [
    { value: '', text: 'Escoja una opción', hidden: true },
    { value: 'brand', text: 'Marca' },
    { value: 'category', text: 'Categoría' },
    { value: 'individual_products', text: 'Producto individual' }
  ]

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  var abortClientController = new AbortController()
  var abortProductController = new AbortController()
  const abortCurrentFetchs = () => {
    abortClientController.abort()
    abortProductController.abort()
    abortController.abort()
  }
  window.addEventListener('beforeunload', abortCurrentFetchs)
  //////

  // Stops client fetch when is unfocus
  const abortClientFetchs = () => {
    abortClientController.abort()
  }
  //

  // Stops products fetch when is unfocus
  const abortProductFetchs = () => {
    abortProductController.abort()
  }
  //

  const openUrlNewWindow = (url) => {
    window.open(url, '_blank')
  }

  const handleRecurrenceRuleChange = (newRule) => {
    setRecurrenceRule(newRule)
  }

  const handleStartTimeChange = (newStartTime) => {
    setNewStartTime(newStartTime)
  }

  const handleEndTimeChange = (newEndTime) => {
    setNewEndTime(newEndTime)
  }

  const handleChangeQualifierA = (event) => {
    if (event != null) {
      setQualifierA(event?.map((e) => e.value))
    }
  }

  const handleChangeQualifierB = (event) => {
    if (event != null) {
      setQualifierB(event?.map((e) => e.value))
    }
  }

  const handleChangeQualifierC = (event) => {
    if (event != null) {
      setQualifierC(event?.map((e) => e.value))
    }
  }

  const handleProductSelectionChange = (event) => {
    setProductSelection(event.target.value)
    setProducts([])
    setBrand(null)
    setCategory(null)
    setInstruction('')
    setImages([])
  }

  const handleInstructionInput = (event) => {
    const { keyCode, target } = event
    const { selectionStart, value } = target

    if (keyCode === 13) {
      target.value = [...value]
        ?.map((c, index) => (index === selectionStart - 1 ? `\n${pointWithSpace}` : c))
        .join('')

      target.selectionStart = selectionStart + pointWithSpace.length
      target.selectionEnd = selectionStart + pointWithSpace.length
    }

    if (value[0] !== newLinePoint) {
      target.value = `${pointWithSpace}${value}`
    }

    // remove extra bullets
    let splitByBullets = target.value.split(`\n${pointWithSpace}`)
    let noExtraBullets = splitByBullets
      .filter((e, i) => e !== '' || i === splitByBullets.length - 1)
      .join(`\n${pointWithSpace}`)

    target.value = noExtraBullets
  }

  const handleInstructionChange = (event) => {
    setInstruction(event.target.value)
  }

  const isAnyKindOfProductSelected = () => {
    if (products?.length > 0 || brand || category) {
      return true
    }
    return false
  }

  const isClientOrSomeQualifierSelected = () => {
    if (clients?.length > 0 || isSomeQualifierSelected()) {
      return true
    }
    return false
  }

  const isSomeQualifierSelected = () => {
    if (qualifierA?.length > 0 || qualifierB?.length > 0 || qualifierC?.length > 0) {
      return true
    }
    return false
  }

  const handleClientOrAttributeChange = (event) => {
    setClientOrAttribute(event.target.value)
    setClients([])
    setQualifierA([])
    setQualifierB([])
    setQualifierC([])
    setUserFilterChecked(false)
    setUsers([])
  }

  const handleBrandsChange = (event) => {
    if (event?.brand) {
      setBrand(event.brand)
    }
  }

  const handleCategoryChange = (event) => {
    if (event?.category) {
      setCategory(event.category)
    }
  }

  const getClient = async (inputValue) => {
    if (inputValue.length >= 3) {
      abortClientController = new AbortController()
      return fetch(
        `${BACKEND_URL}/businesses/${cookie.load('business_id')}/clients?q=${inputValue}&active=true`,
        {
          method: 'GET',
          signal: abortClientController.signal,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        }
      )
        .then((response) => response.json())
        .then((data) =>
          data.table.map((cl) => ({
            value: cl.name,
            label: `${cl.name} - ${cl.db_ref}`,
            client: cl
          }))
        )
        .catch((error) => {
          if (error.name != 'AbortError') {
            console.error('Error fetching clients:', error)
            return []
          }
        })
    }
  }

  const getQualifiers = () => {
    const url = USE_BUSINESS_URL
      ? `${BACKEND_URL}/businesses/${cookie.load('business_id')}/promo_qualifiers`
      : `${BACKEND_URL}/promo_qualifiers`

    fetch(url, {
      method: 'GET',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setQualifiers(data.result[0])
      })
      .catch((e) => console.log(e))
  }

  const displayProductCount = (product_count) => {
    if (product_count == '...') {
      return 'Cargando...'
    }
    let brandCountNum = Number(product_count)
    return isNaN(brandCountNum)
      ? '...'
      : `${brandCountNum} - ${
          brandCountNum > 1 || brandCountNum == 0 ? 'PRODUCTOS ACTIVOS' : 'PRODUCTO ACTIVO'
        }`
  }

  const getUsers = () => {
    fetch(
      `${BACKEND_URL}/businesses/${cookie.load('business_id')}/ubiqua_app_users?non_paginated=true`,
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setUserOptions(
          data.table.map((u) => ({
            value: u.full_name,
            label: `${u.full_name} - ${u.uid}`,
            user: u
          }))
        )
      })
      .catch((e) => console.log(e))
  }

  const getUserForCloning = async (uid) => {
    return fetch(
      `${BACKEND_URL}/businesses/${cookie.load('business_id')}/ubiqua_app_users/${uid}?active=true`,
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        return {
          value: data.full_name,
          label: `${data.full_name} - ${data.uid}`,
          user: data
        }
      })
      .catch((e) => console.log(e))
  }

  const validateAtLeastOneQualifier = (_qualifierA, _qualifierB, _qualifierC) => {
    if (_qualifierA?.length > 0 || _qualifierB?.length > 0 || _qualifierC?.length > 0) {
      return 'success'
    }
    return null
  }

  const getProductsCount = async () => {
    let type = ''
    let typeData = null
    if (category) {
      type = 'category'
      typeData = category.db_ref
    }
    if (brand) {
      type = 'brand'
      typeData = brand.db_ref
    }
    if (typeData) {
      if (countsCache[type][typeData]) {
        setProductCount(countsCache[type][typeData])
      } else {
        setProductCount('...')
        let resp = await fetch(
          `${BACKEND_URL}/businesses/${cookie.load('business_id')}/products?by_${type}=${typeData}&count_only=true`,
          {
            method: 'GET',
            signal: abortController.signal,
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + cookie.load('token')
            }
          }
        )
        if (resp.status != 200) {
          console.error('Error al obtener el conteo de productos')
          setProductCount(0)
        }

        let data = await resp.json()
        countsCache[type][typeData] = data.result
        setProductCount(data.result)
      }
    } else {
      setProductCount('...')
    }
  }

  const clearProductBrandOrCategory = () => {
    setBrand(null)
    setCategory(null)
  }

  const getProductsBaseData = () => {
    fetch(`${BACKEND_URL}/businesses/${cookie.load('business_id')}/products/promo_necessary_data`, {
      method: 'GET',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setProductsBase(data)
      })
      .catch((e) => console.log(e))
  }

  const qualifierAOptions =
    qualifiers?.qualifier_2?.map((value) => ({
      value: value,
      label: value,
      qualifier_2: value
    })) || []

  const qualifierBOptions =
    qualifiers?.qualifier_3?.map((value) => ({
      value: value,
      label: value,
      qualifier_3: value
    })) || []

  const qualifierCOptions =
    qualifiers?.qualifier_4?.map((value) => ({
      value: value,
      label: value,
      qualifier_4: value
    })) || []

  const getProducts = async (inputValue) => {
    if (inputValue.length >= 3) {
      abortProductController = new AbortController()
      return fetch(
        `${BACKEND_URL}/businesses/${cookie.load('business_id')}/products?q=${replaceAccentedCharacters(
          inputValue
        )}&productactive=true`,
        {
          method: 'GET',
          signal: abortProductController.signal,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then((data) => {
          return data.table.map((p) => ({
            value: p.name,
            label: `${p.name} - ${p.db_ref}`,
            product: p
          }))
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }
  const handleClientOption = (options) => {
    setClients(options)
  }

  const handleUsersChange = (options) => {
    setUsers(options)
  }

  const handleProductOption = (options) => {
    if (!options?.product) {
      return
    }
    let alreadyExist = products.find((e) => e.id === options.product.id)
    if (alreadyExist) {
      return
    }
    setProducts([...products, options.product])
  }

  const handleImageChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList)
  }

  const convertByteToMb = (imgBytes) => {
    let mb = 0
    if (imgBytes) {
      mb = Number(imgBytes) / 1024 / 1024
    }
    return mb.toFixed(1) + 'MB'
  }

  const handleShowImage = (event) => {
    setShowImage(true)
  }

  const sleep = (ms) =>
    // eslint-disable-next-line no-undef
    new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })

  const collectData = () => {
    let rule = recurrenceRule

    let jsonData = {
      description: description,
      recurrence_format: rule ? rule.toString() : 'null',
      recurrence_interval: rule ? rule.options.interval : null,
      recurrence_description: rule.toText(SPANISH),
      start_date: fixDateString(newStartTime),
      end_date: fixDateString(newEndTime),
      qualifier_1: clients.map((c) => c.client.db_ref).sort(),
      qualifier_2: qualifierA,
      qualifier_3: qualifierB,
      qualifier_4: qualifierC,
      user_uids: userFilterChecked ? users.map((u) => u.user.uid).sort() : [],
      user_names: userFilterChecked ? users.map((u) => u.label).sort() : [],
      products_selection_type: productSelection,
      category_db_ref: category?.db_ref,
      category_name: category?.description,
      brand_db_ref: brand?.db_ref,
      brand_name: brand?.name,
      products_db_ref: products.map((p) => p.db_ref).sort(),
      product_names: products.map((p) => p.db_ref + ' ' + p.name).sort(),
      task_quantity: 1,
      instructions: instruction,
      image_data: images[0]?.data_url || null,
      image_url: images[0]?.image_url || null,
      image_reference: images[0]?.image_reference || null
    }

    formDataCollector(jsonData)
  }

  const setUpTask = async () => {
    if (taskData) {
      try {
        setCloningTask(true)
        setDescription(taskData.description)

        if (taskData.client_names?.length > 0) {
          setClientOrAttribute('client')
          let _clients = []
          for (const clientName of taskData.qualifier_1) {
            let c = await getClient(clientName)
            if (c?.length > 0) {
              _clients.push(c[0])
            }
          }
          setClients(_clients)
        } else {
          setClientOrAttribute('qualifier')
          if (qualifierAOptions?.length > 0) {
            setQualifierA(taskData.qualifier_2)
          }
          if (qualifierBOptions?.length > 0) {
            setQualifierB(taskData.qualifier_3)
          }
          if (qualifierCOptions?.length > 0) {
            setQualifierC(taskData.qualifier_4)
          }
        }

        if (taskData.user_uids?.length > 0) {
          let _users = []
          for (const uid of taskData.user_uids) {
            let user = await getUserForCloning(uid)
            if (user) {
              _users.push(user)
            }
          }
          setUsers(_users)
          setUserFilterChecked(true)
        }
      } catch (e) {
        console.log('Error en duplicacion de tarea  (Clientes/Atributos/Usuarios): ', e)
        setFailedCreation(
          'Hubo un error al intentar obtener: Los clientes o atributos o usuarios, requeridos para crear la copia de la tarea. Por favor, asegúrese de completar todos los campos faltantes en adelante.'
        )
        setTimeout(() => setFailedCreation(false), 15000)
        return setCloningTask(false)
      }
      try {
        switch (taskData.products_selection_type) {
          case 'individual_products':
            {
              setProductSelection('individual_products')
              let _products = []
              for (const productDbRef of taskData.products_db_ref) {
                let p = await getProducts(productDbRef)
                if (p?.length > 0) {
                  _products.push(p[0]?.product)
                }
              }
              let cleanProducts = _products.filter(
                (value, index, self) => index === self.findIndex((t) => t.db_ref === value.db_ref)
              )
              setProducts(cleanProducts)
            }
            break
          case 'brand':
            {
              setProductSelection('brand')
              let _brand = productsBase?.brand?.find((b) => b.db_ref === taskData.brand_db_ref)
              setBrand(_brand)
            }
            break
          case 'category':
            {
              setProductSelection('category')
              let _category = productsBase?.categories?.find(
                (c) => c.db_ref === taskData.category_db_ref
              )
              setCategory(_category)
            }
            break
        }
      } catch (e) {
        console.log('Error en duplicacion de tarea  (Productos/Marcas/Categorias): ', e)
        setFailedCreation(
          'Hubo un error al intentar obtener: Productos/Marcas/Categorias, requeridos para crear la copia de la tarea. Por favor, asegúrese de completar todos los campos faltantes en adelante.'
        )
        setTimeout(() => setFailedCreation(false), 15000)
        return setCloningTask(false)
      }

      if (taskData.image_url != null) {
        setImages([{ image_url: taskData.image_url, image_reference: taskData.image_reference }])
      }

      if (taskData.instructions != null) {
        setInstruction(taskData.instructions)
      }

      setCloningTask(false)
      await sleep(1000)
      setSaving(false)
    }
  }

  useEffect(() => {
    const isStep3Completed =
      clients.length > 0 || qualifierA.length > 0 || qualifierB.length > 0 || qualifierC.length > 0

    setIsStep3Completed(isStep3Completed)
  }, [clients, qualifierA, qualifierB, qualifierC])

  useEffect(() => {
    getQualifiers()
    getUsers()
    getProductsBaseData()
  }, [])

  useEffect(() => {
    if (taskData) {
      setUpTask()
    }
  }, [taskData, productsBase.brand, productsBase.categories])

  useEffect(() => {
    getProductsCount()
  }, [category, brand])

  useEffect(() => {
    if (readyToCollect) {
      collectData()
    }
  }, [readyToCollect])

  if (cloningTask) {
    return null
  }
  return (
    <>
      <TaskDescription initialDescription={description} onDescriptionChange={setDescription} />

      <div>
        <TaskClientOrAttribute
          clientOrAttribute={clientOrAttribute}
          handleClientOrAttributeChange={handleClientOrAttributeChange}
          clients={clients}
          handleClientOption={handleClientOption}
          abortClientFetchs={abortClientFetchs}
          getClient={getClient}
          qualifierA={qualifierA}
          handleChangeQualifierA={handleChangeQualifierA}
          qualifierAOptions={qualifierAOptions}
          qualifierB={qualifierB}
          handleChangeQualifierB={handleChangeQualifierB}
          qualifierBOptions={qualifierBOptions}
          qualifierC={qualifierC}
          handleChangeQualifierC={handleChangeQualifierC}
          qualifierCOptions={qualifierCOptions}
          validateAtLeastOneQualifier={validateAtLeastOneQualifier}
          description={description}
          isClientOrSomeQualifierSelected={isClientOrSomeQualifierSelected}
          userFilterChecked={userFilterChecked}
          setUserFilterChecked={setUserFilterChecked}
          users={users}
          userOptions={userOptions}
          handleUsersChange={handleUsersChange}
        />

        <div className="promo-form-section">
          <div className="title-promos-form">
            <b class="bold-step">
              <span style={{ fontStyle: 'italic' }}>Opcional</span> - PASO 4: Escoja los productos
              que aplican a esta tarea
            </b>
          </div>
          <hr className="hr-promos-form" />
          <p>Por marca, categoría o productos individuales</p>
          <div className="form-control-width">
            <select
              className={`select-promo-type form-control custom-select form-control-width ${
                !isClientOrSomeQualifierSelected() && `inactive`
              }`}
              name="taskType"
              value={productSelection}
              onChange={handleProductSelectionChange}
              disabled={!isClientOrSomeQualifierSelected()}
            >
              {productSelectionType?.map((option) => (
                <option key={option.value + 1} value={option.value} hidden={option.hidden}>
                  {option.text}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-50" />
        </div>

        {productSelection === 'individual_products' && (
          <div className="promo-form-section">
            <div className="title-promos-form">
              <p>
                <b>PASO 4.1: Escoja los productos que aplican a esta tarea</b>
              </p>
            </div>
            <div className="form-control-width">
              <div className="fake-validator-container">
                <input
                  className="input-fake-validator"
                  type="text"
                  required
                  value={products.length >= 1 ? products : null}
                />
                <AsyncSelect
                  className="clients"
                  placeholder={'Escribir nombre o código del producto'}
                  noOptionsMessage={() => 'No hay resultados'}
                  loadingMessage={() => 'Cargando productos...'}
                  name="selectOptionProducts"
                  loadOptions={getProducts}
                  isClearable={true}
                  searchParamName={'q'}
                  isSearchable={true}
                  value={[]}
                  onChange={(e, am) => {
                    handleProductOption(e)
                    am.action = 'clear'
                  }}
                  onBlur={abortProductFetchs}
                />
              </div>
            </div>
            {products?.length > 0 && (
              <div className="promo-form-section row">
                <table className="table table-hover promos-product-table">
                  <thead className="table-header-pdv">
                    <tr>
                      <th className="pl-20">CÓDIGO</th>
                      <th>NOMBRE DEL PRODUCTO</th>
                      <th>UNIDAD DE VENTA 1</th>
                      <th>UNIDAD DE VENTA 2</th>
                      <th className="centered">ACCIÓN</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products?.map((product, index) => (
                      <tr key={`${product.id}-${index}`}>
                        <td className="pl-20">{product.db_ref}</td>
                        <td>{product.name}</td>
                        <td>
                          {product.packages[0]?.unit && (
                            <>
                              {product.packages[0]?.unit}{' '}
                              {product.packages[0]?.quantity > 1
                                ? `de ${product.packages[0]?.quantity}`
                                : ''}
                            </>
                          )}
                          {!product.packages[0]?.unit && '-'}
                        </td>
                        <td>
                          {product.packages[1]?.unit && (
                            <>
                              {product.packages[1]?.unit}{' '}
                              {product.packages[1]?.quantity > 1
                                ? `de ${product.packages[1]?.quantity}`
                                : ''}
                            </>
                          )}
                          {!product.packages[1]?.unit && '-'}
                        </td>
                        <td
                          className="delete-selected-product"
                          onClick={(e) => setProducts(products.filter((p) => p.id !== product.id))}
                        >
                          &times;
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}

        {productSelection === 'brand' && (
          <div className="promo-form-section">
            <div className="title-promos-form">
              <p>
                <b>PASO 4.1: Seleccione la marca de los productos para esta tarea</b>
              </p>
            </div>
            <div className="form-control-width">
              <div className="fake-validator-container">
                <input
                  className="input-fake-validator"
                  type="text"
                  required
                  value={brand?.db_ref ? brand?.db_ref : null}
                />
                <Select
                  isDisabled={brand ? true : false}
                  placeholder={'Escoja una opcion'}
                  noOptionsMessage={() => 'No hay resultados'}
                  loadingMessage={() => 'Cargando...'}
                  className="qualifier"
                  isClearable={false}
                  value={brand ? { value: brand.db_ref, label: brand.name } : null}
                  onChange={(e) => handleBrandsChange(e)}
                  options={productsBase?.brand?.map((option) => {
                    return { value: option.db_ref, label: option.name, brand: option }
                  })}
                />
              </div>
            </div>
            {isAnyKindOfProductSelected() && (
              <div className="box-products-preview-container-pos mt-20">
                <div className="box-products-preview-pos mr-10">
                  <p className="m-0" title={brand?.name}>
                    {displayProductCount(productCount)}
                  </p>
                  <div onClick={clearProductBrandOrCategory} className="delete-selected-product">
                    &times;
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() =>
                    openUrlNewWindow(`/productos?by_brand=${brand.db_ref}&brand_name=${brand.name}`)
                  }
                  className="big-btn-tasks"
                >
                  Ver lista de productos
                </button>
              </div>
            )}
            <div className="mb-50" />
          </div>
        )}
        {productSelection === 'category' && (
          <div className="promo-form-section">
            <div className="title-promos-form">
              <p>
                <b>PASO 4.1: Seleccione la categoría de los productos para esta tarea</b>
              </p>
            </div>
            <div className="form-control-width">
              <div className="fake-validator-container">
                <input
                  className="input-fake-validator"
                  type="text"
                  required
                  value={category?.db_ref ? category?.db_ref : null}
                />
                <Select
                  isDisabled={category ? true : false}
                  placeholder={'Escoja una opcion'}
                  noOptionsMessage={() => 'No hay resultados'}
                  loadingMessage={() => 'Cargando...'}
                  className="qualifier"
                  isClearable={true}
                  value={category ? { value: category.db_ref, label: category.description } : null}
                  onChange={(e) => handleCategoryChange(e)}
                  options={productsBase?.categories?.map((option) => {
                    return { value: option.db_ref, label: option.description, category: option }
                  })}
                />
              </div>
            </div>
            {isAnyKindOfProductSelected() && (
              <div className="box-products-preview-container-pos mt-20">
                <div className="box-products-preview-pos mr-10">
                  <p className="m-0" title={category?.description}>
                    {displayProductCount(productCount)}
                  </p>
                  <div onClick={clearProductBrandOrCategory} className="delete-selected-product">
                    &times;
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() =>
                    openUrlNewWindow(
                      `/productos?by_category=${category.db_ref}&category_name=${category.description}`
                    )
                  }
                  className="big-btn-tasks"
                >
                  Ver listado de productos
                </button>
              </div>
            )}
            <div className="mb-50" />
          </div>
        )}
        <div className="promo-form-section">
          <div className="title-promos-form">
            <b class="bold-step">
              <span style={{ fontStyle: 'italic' }}>Opcional</span> - PASO 5: Adjunte una imagen de
              referencia
            </b>
          </div>
          <hr className="hr-promos-form" />
          <div className="form-control-width">
            <ImageUploading
              multiple
              value={images}
              onChange={handleImageChange}
              maxNumber={maxNumber}
              maxFileSize={maxMbFileSize}
              resolutionType={'less'}
              acceptType={['jpg', 'png']}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
                errors
              }) => (
                <div className="upload__image-wrapper">
                  {imageList.length < 1 && (
                    <div className="form-group">
                      <div className="">
                        <div
                          className="planimetry-upload-drop-zone"
                          id="drop-zone"
                          style={isDragging ? { background: '#F0F4F7' } : undefined}
                          {...dragProps}
                        >
                          <div className="drag-drop-task-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="53"
                              height="54"
                              viewBox="0 0 53 54"
                              fill="none"
                            >
                              <path
                                d="M18.25 22.25L26.25 14.25L34.25 22.25"
                                stroke="#597FA9"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M26 52L26.25 17.9299"
                                stroke="#597FA9"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12.9912 10.8999C15.3407 5.93217 20.3954 2.5 26.25 2.5C33.6345 2.5 39.7464 7.9613 40.7619 15.0646C40.8511 15.6884 41.3906 16.1483 42.0206 16.1375C42.067 16.1368 42.1126 16.1364 42.1591 16.1364C46.4894 16.1364 50 19.6469 50 23.9773C50 28.3076 46.4894 31.8182 42.1591 31.8182L29.25 31.8183V34.3183L42.1591 34.3182C47.8701 34.3182 52.5 29.6883 52.5 23.9773C52.5 18.5684 48.3471 14.1293 43.056 13.6747C41.4451 5.86827 34.5333 0 26.25 0C19.6491 0 13.9222 3.72721 11.0528 9.18678C4.82295 9.95655 0 15.2665 0 21.7043C0 28.6705 5.64726 34.3184 12.6136 34.3184L22.25 34.3183V31.8183L12.6136 31.8184C7.02811 31.8184 2.5 27.2899 2.5 21.7043C2.5 16.3446 6.67087 11.9579 11.9429 11.6128C12.3959 11.5832 12.7972 11.3103 12.9912 10.8999Z"
                                fill="#597FA9"
                              />
                            </svg>
                          </div>
                          <div className="planimetry-drop-text">
                            <b>Seleccione una sola imagen o arrástrela y suéltela aquí</b>
                          </div>
                          <div className="planimetry-drop-subtext">
                            JPG o PNG, tamaño del archivo no mayor a 5MB
                          </div>
                          <div className="planimetry-drop-subtext">ó</div>
                          <button
                            onClick={onImageUpload}
                            className="select-photo-button"
                            type="button"
                          >
                            Seleccionar imagen
                          </button>
                        </div>
                      </div>
                      {errors && (
                        <div className="img-error-message">
                          {errors.maxNumber && <span>Máximo de 1 imagen por tarea</span>}
                          {errors.maxFileSize && (
                            <span>
                              Asegúrate de que la imagen sea en formato .png o .jpg y tenga un
                              tamaño no mayor a 5MB.
                            </span>
                          )}
                          {errors.acceptType && (
                            <span>
                              Asegúrate de que la imagen sea en formato .png o .jpg y tenga un
                              tamaño no mayor a 5MB.
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {imageList?.map((image, index) => (
                    <div className="promo-form-section">
                      <div
                        className="title-promos-form"
                        style={{ marginTop: -30, fontSize: 13, marginBottom: 10 }}
                      >
                        <b>Imagen agregada</b>
                      </div>
                      {image['data_url'] != undefined && (
                        <div className="img-load-wrapper">
                          <div className="img-load-div">
                            <div className="img-load-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="22"
                                viewBox="0 0 21 22"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M14.3451 7.63889C15.5702 7.63889 16.5634 6.61287 16.5634 5.34722C16.5634 4.08157 15.5702 3.05556 14.3451 3.05556C13.1199 3.05556 12.1268 4.08157 12.1268 5.34722C12.1268 6.61287 13.1199 7.63889 14.3451 7.63889ZM14.3451 6.11111C14.7534 6.11111 15.0845 5.76911 15.0845 5.34722C15.0845 4.92534 14.7534 4.58333 14.3451 4.58333C13.9367 4.58333 13.6056 4.92534 13.6056 5.34722C13.6056 5.76911 13.9367 6.11111 14.3451 6.11111Z"
                                  fill="#597FA9"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M3.5493 0C1.58907 0 0 1.64162 0 3.66667V18.3333C0 20.3584 1.58907 22 3.5493 22H17.4507C19.4109 22 21 20.3584 21 18.3333V3.66667C21 1.64162 19.4109 0 17.4507 0H3.5493ZM17.4507 1.83333H3.5493C2.56919 1.83333 1.77465 2.65414 1.77465 3.66667V13.1023L6.17425 8.0316L11.3607 13.8334L14.6209 10.5455L19.2254 14.4085V3.66667C19.2254 2.65415 18.4308 1.83333 17.4507 1.83333ZM19.2254 16.3767L14.7113 12.5894L11.3058 16.0238L6.19058 10.3017L1.77465 15.3913V18.3333C1.77465 19.3459 2.56919 20.1667 3.5493 20.1667H17.4507C18.4308 20.1667 19.2254 19.3459 19.2254 18.3333V16.3767Z"
                                  fill="#597FA9"
                                />
                              </svg>
                            </div>
                            <span className="img-load-text">{image['file'].name}</span>
                            <button
                              type="button"
                              className="img-view-buttom"
                              onClick={(e) => {
                                handleShowImage(e)
                              }}
                            >
                              {' '}
                              Ver imagen
                            </button>
                          </div>
                          <span className="img-load-text" style={{ marginRight: 40 }}>
                            {convertByteToMb(image['file'].size)}
                          </span>
                          <button
                            type="button"
                            className="delete-image-button"
                            onClick={() => onImageRemove(index)}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ margin: '0 !important' }}
                            >
                              <circle cx="12" cy="12" r="12" fill="#202020" />
                              <path
                                d="M12.6266 12L15.8666 8.75995C15.9395 8.67493 15.9775 8.56556 15.9732 8.45371C15.9689 8.34185 15.9225 8.23575 15.8433 8.15659C15.7642 8.07744 15.6581 8.03107 15.5462 8.02675C15.4344 8.02243 15.325 8.06048 15.24 8.13329L12 11.3733L8.75997 8.12884C8.67495 8.05603 8.56558 8.01798 8.45373 8.0223C8.34187 8.02662 8.23577 8.07299 8.15661 8.15215C8.07746 8.2313 8.03109 8.33741 8.02677 8.44926C8.02245 8.56112 8.0605 8.67049 8.13331 8.75551L11.3733 12L8.12886 15.24C8.08234 15.2798 8.04455 15.3288 8.01787 15.384C7.9912 15.4391 7.97621 15.4992 7.97384 15.5604C7.97148 15.6216 7.98179 15.6826 8.00414 15.7397C8.02648 15.7967 8.06037 15.8485 8.10369 15.8918C8.147 15.9351 8.1988 15.969 8.25583 15.9913C8.31286 16.0137 8.3739 16.024 8.43511 16.0216C8.49632 16.0193 8.55638 16.0043 8.61152 15.9776C8.66666 15.9509 8.71569 15.9131 8.75553 15.8666L12 12.6266L15.24 15.8666C15.325 15.9394 15.4344 15.9775 15.5462 15.9732C15.6581 15.9688 15.7642 15.9225 15.8433 15.8433C15.9225 15.7642 15.9689 15.6581 15.9732 15.5462C15.9775 15.4343 15.9395 15.325 15.8666 15.24L12.6266 12Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        </div>
                      )}
                      {image['image_url'] != undefined && (
                        <div className="img-load-wrapper">
                          <div className="img-load-div">
                            <div className="img-load-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="22"
                                viewBox="0 0 21 22"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14.3451 7.63889C15.5702 7.63889 16.5634 6.61287 16.5634 5.34722C16.5634 4.08157 15.5702 3.05556 14.3451 3.05556C13.1199 3.05556 12.1268 4.08157 12.1268 5.34722C12.1268 6.61287 13.1199 7.63889 14.3451 7.63889ZM14.3451 6.11111C14.7534 6.11111 15.0845 5.76911 15.0845 5.34722C15.0845 4.92534 14.7534 4.58333 14.3451 4.58333C13.9367 4.58333 13.6056 4.92534 13.6056 5.34722C13.6056 5.76911 13.9367 6.11111 14.3451 6.11111Z"
                                  fill="#597FA9"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M3.5493 0C1.58907 0 0 1.64162 0 3.66667V18.3333C0 20.3584 1.58907 22 3.5493 22H17.4507C19.4109 22 21 20.3584 21 18.3333V3.66667C21 1.64162 19.4109 0 17.4507 0H3.5493ZM17.4507 1.83333H3.5493C2.56919 1.83333 1.77465 2.65414 1.77465 3.66667V13.1023L6.17425 8.0316L11.3607 13.8334L14.6209 10.5455L19.2254 14.4085V3.66667C19.2254 2.65415 18.4308 1.83333 17.4507 1.83333ZM19.2254 16.3767L14.7113 12.5894L11.3058 16.0238L6.19058 10.3017L1.77465 15.3913V18.3333C1.77465 19.3459 2.56919 20.1667 3.5493 20.1667H17.4507C18.4308 20.1667 19.2254 19.3459 19.2254 18.3333V16.3767Z"
                                  fill="#597FA9"
                                />
                              </svg>
                            </div>
                            <span className="img-load-text">{image['image_reference']}</span>
                            <button
                              type="button"
                              className="img-view-buttom"
                              onClick={(e) => {
                                handleShowImage(e)
                              }}
                            >
                              {' '}
                              Ver imagen
                            </button>
                          </div>
                          <span className="img-load-text" style={{ marginRight: 40 }}></span>
                          <button
                            type="button"
                            className="delete-image-button"
                            onClick={() => onImageRemove(index)}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ margin: '0 !important' }}
                            >
                              <circle cx="12" cy="12" r="12" fill="#202020" />
                              <path
                                d="M12.6266 12L15.8666 8.75995C15.9395 8.67493 15.9775 8.56556 15.9732 8.45371C15.9689 8.34185 15.9225 8.23575 15.8433 8.15659C15.7642 8.07744 15.6581 8.03107 15.5462 8.02675C15.4344 8.02243 15.325 8.06048 15.24 8.13329L12 11.3733L8.75997 8.12884C8.67495 8.05603 8.56558 8.01798 8.45373 8.0223C8.34187 8.02662 8.23577 8.07299 8.15661 8.15215C8.07746 8.2313 8.03109 8.33741 8.02677 8.44926C8.02245 8.56112 8.0605 8.67049 8.13331 8.75551L11.3733 12L8.12886 15.24C8.08234 15.2798 8.04455 15.3288 8.01787 15.384C7.9912 15.4391 7.97621 15.4992 7.97384 15.5604C7.97148 15.6216 7.98179 15.6826 8.00414 15.7397C8.02648 15.7967 8.06037 15.8485 8.10369 15.8918C8.147 15.9351 8.1988 15.969 8.25583 15.9913C8.31286 16.0137 8.3739 16.024 8.43511 16.0216C8.49632 16.0193 8.55638 16.0043 8.61152 15.9776C8.66666 15.9509 8.71569 15.9131 8.75553 15.8666L12 12.6266L15.24 15.8666C15.325 15.9394 15.4344 15.9775 15.5462 15.9732C15.6581 15.9688 15.7642 15.9225 15.8433 15.8433C15.9225 15.7642 15.9689 15.6581 15.9732 15.5462C15.9775 15.4343 15.9395 15.325 15.8666 15.24L12.6266 12Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </ImageUploading>
          </div>
        </div>
        <div className="promo-form-section">
          <div className="title-promos-form">
            <p>
              <b class="bold-step">
                <span style={{ fontStyle: 'italic' }}>Opcional</span> - PASO 5.1: Agregue
                información o instrucciones necesarias para completar esta tarea
              </b>
            </p>
          </div>
          <div className="form-group form-control-width">
            <textarea
              type="text"
              maxLength="1000"
              className="form-control"
              style={{ height: 78 }}
              id="instructions"
              name="instructions"
              onChange={handleInstructionChange}
              value={instruction}
              placeholder="Escriba aqui..."
              onKeyUp={handleInstructionInput}
              rows="10"
            />
            <p className="small text-muted mt-10 pull-right">
              Cantidad máxima de caracteres {Math.abs(instruction.length - 1000)}/1000
            </p>
          </div>
        </div>
      </div>
      <TaskScheduleConfig
        onRecurrenceChange={handleRecurrenceRuleChange}
        onStartChange={handleStartTimeChange}
        onEndChange={handleEndTimeChange}
      />
      {images?.map(
        (image, index) =>
          (image['data_url'] || image['image_url']) && (
            <div key={index}>
              <ImageModal
                imgSrc={image['data_url'] || image['image_url']}
                showImage={showImage}
                setShowImage={setShowImage}
              />
            </div>
          )
      )}
    </>
  )
}
export default PlanimetryVerificationTask
