import React, { useEffect, useState } from 'react'
import TopBar from '../../layout/TopBar'
import NavBar from '../../layout/NavBar'
import InventoryCaptureTask from './InventoryCaptureTask'
import SurveyTask from './SurveysTask'
import PlanimetryVerificationTask from './PlanimetryVerificationTask'
import PriceCaptureTask from './PriceCaptureTask'

import cookie from 'react-cookies'
import { TASK_TYPES } from '../constants/constants'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BACKEND_URL = USE_BUSINESS_URL
  ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : process.env.REACT_APP_BACKEND_URL

const TaskLayout = () => {
  const taskTypeOptions = [
    { value: '', text: 'Escoja una opción', hidden: true },
    { value: TASK_TYPES.inventory.key, text: TASK_TYPES.inventory.name },
    { value: TASK_TYPES.planimetry.key, text: TASK_TYPES.planimetry.name },
    { value: TASK_TYPES.survey.key, text: TASK_TYPES.survey.name },
    { value: TASK_TYPES.price_capture.key, text: TASK_TYPES.price_capture.name }
  ]

  const [selectedTaskType, setSelectedTaskType] = useState(null)
  const [saving, setSaving] = useState(false)
  const [readyToCollect, setReadyToCollect] = useState(false)
  const [failedCreation, setFailedCreation] = useState(false)
  const [taskData, setTaskData] = useState(null)
  const [cloningTask, setCloningTask] = useState(false)
  const [isRequired, setIsRequired] = useState(false)
  const [collapsed, setCollapsed] = useState(false)
  const [showProductAlert, setShowProductAlert] = useState(false)
  const [isSurveyLimitReached, setIsSurveyLimitReached] = useState(false)

  const [isStep3Completed, setIsStep3Completed] = useState(false)

  const handleTaskTypeChange = (event) => {
    setSelectedTaskType(event.target.value)
  }

  const sendForm = (event) => {
    event.preventDefault()

    if (isSurveyLimitReached) {
      setFailedCreation(
        'No es posible guardar la tarea porque supera el límite permitido de 50 preguntas. Para continuar,  elimine las preguntas que excedan el límite e intente guardar nuevamente.'
      )
      return
    }

    if (!isStep3Completed) {
      return
    }
    setFailedCreation(false)
    setReadyToCollect(true)
    setIsRequired(false)
    setShowProductAlert(false)
  }

  const formDataCollector = (data) => {
    let finalData = {
      ...data,
      business_uid: cookie.load('business_tenant_uid'),
      task_type: selectedTaskType
    }
    postTaskToBackend(finalData)
    setReadyToCollect(false)
  }

  const postTaskToBackend = (body) => {
    setSaving(true)
    fetch(`${BACKEND_URL}/pos_tasks`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify(body)
    })
      .then((r) => evaluateResponse(r))
      .catch((e) => console.log(e))
  }

  const startCloning = async (uuid) => {
    let taskToClone = await getTaskToClone(uuid)
    if (taskToClone) {
      setSaving(true)
      setSelectedTaskType(taskToClone.task_type)
      setTaskData(taskToClone)
    }
  }

  const getTaskToClone = async (uuid) => {
    const response = await fetch(`${BACKEND_URL}/pos_tasks/${uuid}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
    if (response.status === 401) {
      window.location = '/'
    }

    if (response.status != 200) {
      return false
    } else {
      let json = await response.json()
      return json
    }
  }

  const setQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const uuidToClone = urlParams.get('clone') || ''
    if (uuidToClone) {
      startCloning(uuidToClone)
    }
  }

  const handleCloseAlert = () => {
    setShowProductAlert(false)
  }

  useEffect(() => {
    setQueryParams()
  }, [])

  const evaluateResponse = async (response) => {
    // navigate to task details
    switch (response.status) {
      case 422:
      case 409:
        setFailedCreation(
          'Error al crear la nueva tarea. Hay otra tarea idéntica. Modifique la tarea actual o suspenda la tarea existente antes de continuar'
        )
        setSaving(false)
        break
      case 404:
      case 400:
        setFailedCreation('Servidor fuera de linea. Intente más tarde')
        setSaving(false)
        break
      case 500:
        setFailedCreation('Error interno del sistema. Intente más tarde')
        setSaving(false)
        break
      default:
        let data = await response.json()
        setFailedCreation(false)
        // redirect to task details
        window.location = `/ejecucion_punto_venta/detalles/${data['uuid']}?success=true`
        break
    }
  }

  const handleSearch = (event) => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === 13) {
      // to disbale submit form
      event.preventDefault()
      const query = event.target.value
      if (query !== '') {
        window.location = `/ejecucion_punto_venta?query=${query}`
      }
    }
  }

  const cancelCreate = (event) => {
    event.preventDefault()
    const val = window.confirm(
      '¿Esta seguro que quiere continuar? Los cambios editados no seran guardados'
    )
    if (val) {
      window.location = '/ejecucion_punto_venta'
    }
  }

  const handleToggleCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed)
    localStorage.setItem('collapsedState', newCollapsed)
  }

  useEffect(() => {
    const collapsedFromStorage = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedFromStorage)
  }, [])

  return (
    <div>
      <div>
        <div onKeyDown={handleSearch}>
          <TopBar
            searchPlaceholder={'Buscar tareas en punto de venta por código o descripción'}
            userName={''}
            onToggleCollapse={handleToggleCollapse}
            collapsed={collapsed}
          />
        </div>
        <div>
          <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
            {failedCreation && (
              <div className="alert alert-danger" role="alert">
                <strong>{failedCreation}</strong>
              </div>
            )}

            {showProductAlert && (
              <div className="alert alert-danger" role="alert">
                <strong className="ml-65">
                  Por favor, completa todos los campos obligatorios antes de guardar.
                </strong>

                <button
                  type="button"
                  className="close mt--20"
                  onClick={() => setShowProductAlert(false)}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            )}

            <div className="show-title">
              <h2>Crear nueva tarea en punto de venta</h2>
            </div>
            <form onSubmit={sendForm}>
              <div className="index-table">
                <div className="show-area">
                  <div className="create-promo-wrapper">
                    <div className="inline-block full-width">
                      <div className="float-left">
                        <h5>Ingrese los siguientes datos para crear una nueva tarea</h5>
                      </div>
                      <div className="float-right">
                        <button disabled={saving} onClick={cancelCreate} className="cancel-button">
                          Cancelar
                        </button>
                        <input
                          disabled={saving || !isStep3Completed}
                          className="save-button"
                          value={saving ? 'Guardando...' : 'Guardar'}
                          type="submit"
                        />
                      </div>
                    </div>
                    <hr />
                    {cloningTask && (
                      <div className="promo-form-section">
                        <div className="title-promos-form">
                          <b>Cargando tarea...</b>
                        </div>
                      </div>
                    )}
                    {!cloningTask && (
                      <div className="promo-form-section mt-33">
                        <div className="title-promos-form">
                          <b class="bold-step">PASO 1: Escoja el tipo de tarea a crear</b>
                        </div>
                        <hr className="hr-promos-form" />
                        <div className="form-control-width">
                          <select
                            className="select-promo-type form-control custom-select form-control-width"
                            name="taskType"
                            required
                            value={selectedTaskType}
                            onChange={handleTaskTypeChange}
                          >
                            {taskTypeOptions.map((option) => (
                              <option
                                key={option.value + 1}
                                value={option.value}
                                hidden={option.hidden}
                              >
                                {option.text}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    {selectedTaskType === TASK_TYPES.inventory.key && (
                      <InventoryCaptureTask
                        taskData={taskData}
                        setCloningTask={setCloningTask}
                        cloningTask={cloningTask}
                        setFailedCreation={setFailedCreation}
                        failedCreation={failedCreation}
                        readyToCollect={readyToCollect}
                        formDataCollector={formDataCollector}
                        setSaving={setSaving}
                        isStep3Completed={isStep3Completed}
                        setIsStep3Completed={setIsStep3Completed}
                      />
                    )}
                    {selectedTaskType === TASK_TYPES.planimetry.key && (
                      <PlanimetryVerificationTask
                        taskData={taskData}
                        setCloningTask={setCloningTask}
                        cloningTask={cloningTask}
                        setFailedCreation={setFailedCreation}
                        failedCreation={failedCreation}
                        readyToCollect={readyToCollect}
                        formDataCollector={formDataCollector}
                        setSaving={setSaving}
                        isStep3Completed={isStep3Completed}
                        setIsStep3Completed={setIsStep3Completed}
                      />
                    )}
                    {selectedTaskType === TASK_TYPES.survey.key && (
                      <SurveyTask
                        setIsSurveyLimitReached={setIsSurveyLimitReached}
                        isRequired={isRequired}
                        taskData={taskData}
                        setCloningTask={setCloningTask}
                        cloningTask={cloningTask}
                        setFailedCreation={setFailedCreation}
                        failedCreation={failedCreation}
                        readyToCollect={readyToCollect}
                        formDataCollector={formDataCollector}
                        setSaving={setSaving}
                        isStep3Completed={isStep3Completed}
                        setIsStep3Completed={setIsStep3Completed}
                      />
                    )}
                    {selectedTaskType === TASK_TYPES.price_capture.key && (
                      <PriceCaptureTask
                        taskData={taskData}
                        setCloningTask={setCloningTask}
                        cloningTask={cloningTask}
                        setFailedCreation={setFailedCreation}
                        failedCreation={failedCreation}
                        readyToCollect={readyToCollect}
                        formDataCollector={formDataCollector}
                        setSaving={setSaving}
                        setReadyToCollect={setReadyToCollect}
                        setShowProductAlert={setShowProductAlert}
                        isStep3Completed={isStep3Completed}
                        setIsStep3Completed={setIsStep3Completed}
                      />
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        <NavBar activeItem="Ejecución en punto de venta" isEditing={true} collapsed={collapsed} />
      </div>
    </div>
  )
}
export default TaskLayout
