import React, { useEffect, useState } from 'react'
import Pagination from '../layout/Pagination'
import cookie from 'react-cookies'
import { formatMoney } from '../helpers/utils'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import { getTimestampFromDateTime } from '../helpers/formatDate'
import SearchInput from '../layout/SearchInput'
import Toolbar from 'react-multi-date-picker/plugins/toolbar'
import DatePicker from 'react-multi-date-picker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faDownload, faCalendar } from '@fortawesome/free-solid-svg-icons'
import ReactFilter from '../components/inputs/ReactFilter'
import moment from 'moment'
import { Table } from 'react-bootstrap'
import fetchWithAuth from '../helpers/fetchWithAuth'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BACKEND_URL = USE_BUSINESS_URL
  ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : process.env.REACT_APP_BACKEND_URL

function SettlementsIndex() {
  const [isFetching, setIsFetching] = useState(false)
  const [isFetchingFilter, setIsFetchingFilter] = useState(false)

  const [query, setQuery] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [perPageSettlements, setPerPageSettlement] = useState(50)

  const [totalPages, setTotalPages] = useState(0)
  const [title, setTitle] = useState('Liquidaciones')
  const [data, setData] = useState([])
  const [collapsed, setCollapsed] = useState(false)
  const [settlements, setSettlements] = useState([])

  const [fetchDataStatus, setFetchDataStatus] = useState(true)
  const [filters, setFilters] = useState(new Map())
  const [filtersDic, setFiltersDic] = useState({ 'Creado Por': 'user_uid' })
  const [resetReactFilters, setResetReactFilters] = useState(false)
  const [labelToIdMapping, setLabelToIdMapping] = useState({})
  const [allSelectedOptions, setAllSelectedOptions] = useState(null)
  const [filterQuery, setFilterQuery] = useState('')
  const [dateQuery, setDateQuery] = useState(null)
  const [selectedDates, setSelectedDates] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [dataFilter, setDataFilter] = useState([])

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  const abortCurrentFetchs = () => {
    abortController.abort()
  }
  window.addEventListener('beforeunload', abortCurrentFetchs)
  //////

  const filtersDisabled = !settlements || settlements.length === 0

  const setAllSelectedOptionsFunc = (identifier, selectedLabels) => {
    setAllSelectedOptions(
      (prevAllSelectedOptions) => {
        const selectedUids = selectedLabels.map((label) => {
          return labelToIdMapping[label]
        })
        const newAllSelectedOptions = new Map(prevAllSelectedOptions).set(identifier, selectedUids)
        return newAllSelectedOptions
      },
      () => {
        buildFiltersQuery()
      }
    )
  }

  const buildFiltersQuery = () => {
    const values = allSelectedOptions.get('Creado Por') || []
    const queryParam =
      values.length > 0 ? `&client_uid=${encodeURIComponent(values.join(','))}` : ''
    setFilterQuery(queryParam)
  }

  const fetchClientsFromServer = async () => {
    try {
      const response = await fetchWithAuth(
        `${BACKEND_URL}/settlements?client_names_only=true&business_tenant_uid=${cookie.load('business_tenant_uid')}`,
        {
          method: 'GET',
          signal: abortController.signal
        }
      )

      if (response === undefined) {
        setSettlements([])
        console.error('No response from the server.')
        setIsFetching(false)
        return
      }
      setSettlements(response.result)
    } catch (error) {
      console.error('Error fetching clients:', error)
      setSettlements([])
      setTotalPages(0)
    } finally {
      setIsFetching(false)
    }
  }

  const fetchSettlementFromServer = async () => {
    setIsFetchingFilter(false)

    const response = await fetch(
      `${BACKEND_URL}/settlements?page=${currentPage}&per_page=${perPage}${filterQuery || dateQuery ? '&filter_search=true' : ''}${filterQuery || ''}${dateQuery || ''}${query ? `&q=${query}` : ''}&business_tenant_uid=${cookie.load('business_tenant_uid')}`,
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (response.status != 200) {
      setData([])
      setTotalPages(0)
    } else {
      let data = await response.json()
      setData(data.result ? data.result : data)
      setTotalPages(Math.ceil((data.count || data.length) / perPage))
    }
    setIsFetchingFilter(false)
  }

  const handlePaginationClick = (event) => {
    // <a> = when pagination elements were clicked
    if (event.target.tagName === 'A') {
      handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      handlePagination(event.target.parentNode.parentNode)
    }
  }

  const handlePagination = (target) => {
    let targetId = target.id || ''
    let newPage = currentPage

    switch (targetId) {
      case 'first-page':
        if (currentPage !== 1) {
          newPage = 1
        }
        break
      case 'last-page':
        if (currentPage !== totalPages) {
          newPage = totalPages
        }
        break
      case 'previous-page':
        if (currentPage > 1) {
          newPage = currentPage - 1
        }
        break
      case 'next-page':
        if (currentPage < totalPages) {
          newPage = currentPage + 1
        }
        break
      default:
        if (!targetId && currentPage !== 1) {
          newPage = 1
        } else if (!isNaN(targetId)) {
          newPage = parseInt(targetId)
        }
        break
    }

    if (isNaN(newPage)) {
      console.error('Error: newPage is NaN')
      return
    }

    if (newPage !== currentPage) {
      setCurrentPage(newPage)
      const queryParams = new URLSearchParams(window.location.search)
      queryParams.set('page', newPage)
      window.history.pushState({}, '', `${window.location.pathname}?${queryParams.toString()}`)
    }
  }

  const handleSearch = (event) => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === 13) {
      // to disbale submit form
      event.preventDefault()
      const _query = event.target.value
      if (_query !== '') {
        setQuery(_query)
        fetchSettlementFromServer()
      }
    }
  }

  const showModel = (uuid) => {
    window.open('/liquidaciones/detalles/' + uuid, '_blank')
  }

  const processSettlementsForFilter = () => {
    let labelToIdMappingTemp = {}

    const createdByOptions = settlements
      .map((settlement) => {
        const label = `${settlement.user_uid} - ${settlement.user_full_name}`
        labelToIdMappingTemp[label] = settlement.user_uid
        return label
      })
      .filter((value, index, self) => self.indexOf(value) === index)

    setFilters([{ key: 'Creado Por', value: createdByOptions }])
    setLabelToIdMapping(labelToIdMappingTemp)
    setFetchDataStatus((prevStatus) => ({ ...prevStatus, filters: false }))
  }

  const resetFilters = () => {
    setAllSelectedOptions(new Map())
    setFilterQuery('')
    setDateQuery(null)
    setSelectedDates(null)
    setSearchQuery('')
    setDataFilter('')
    setQuery('')
    setCurrentPage(1)
    setResetReactFilters(!resetReactFilters)
  }

  const handleDateFilter = (datesObj) => {
    let queryParam = ''
    let startDate, endDate

    if (!datesObj || datesObj.length === 0) {
      setDateQuery(null)
      setSelectedDates(null)
      setCurrentPage(1)
    } else {
      setSelectedDates(datesObj)

      let formattedDates = datesObj.map((date) => moment.unix(date.unix))

      if (formattedDates.length > 1) {
        startDate = moment.min(formattedDates)
        endDate = moment.max(formattedDates)
      } else {
        startDate = formattedDates[0]
        endDate = formattedDates[0]
      }

      const startDateISO = (startDate).toISOString()
      const endDateISO = (endDate).toISOString()
      queryParam = `&start_date=${startDateISO}&end_date=${endDateISO}`

      setDateQuery(queryParam)
      setCurrentPage(1)
    }
  }

  useEffect(() => {
    if (settlements) {
      processSettlementsForFilter()
    }
  }, [settlements])

  useEffect(() => {
    if (allSelectedOptions) {
      buildFiltersQuery()
    }
  }, [allSelectedOptions])

  useEffect(() => {
    fetchSettlementFromServer()
  }, [query, filterQuery, dateQuery, currentPage])

  useEffect(() => {
    fetchClientsFromServer()
  }, [])

  const handleToggleCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed)
    localStorage.setItem('collapsedState', newCollapsed)
  }

  useEffect(() => {
    const collapsedFromStorage = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedFromStorage)
  }, [])

  return (
    <div>
      <div>
        <div onKeyDown={handleSearch}>
          <TopBar onToggleCollapse={handleToggleCollapse} collapsed={collapsed} />
        </div>
        <div onClick={handlePaginationClick}>
          {isFetching && (
            <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
              <div className="index-header">
                <br />
                <p>Obteniendo datos ...</p>
              </div>
            </div>
          )}
          {!isFetching && (
            <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
              <div className="index-header detailed-container">
                <h2>{title}</h2>
              </div>
              <div className="index-table">
                <div className="show-area">
                  <h4 className="text-sub-title">Filtrar liquidaciones por:</h4>
                  <div className="filters-search-container mb-10">
                    <FontAwesomeIcon className="filter-icon mr-10" icon={faFilter} />
                    <div className="filters-container">
                      {Array.from(filters).map((filter, indx) => (
                        <div key={`flr-${indx}`} className="mr-10">
                          <ReactFilter
                            className="mr-10"
                            title={filter.key}
                            identifier={filter.key}
                            options={filter.value}
                            resetWatcher={resetReactFilters}
                            disabled={filtersDisabled}
                            onChange={(e) => {
                              let so = new Map(allSelectedOptions)
                              let selectedFullNames

                              if (e.identifier === 'Creado Por') {
                                selectedFullNames = e.selectedOptions.map(
                                  (option) => labelToIdMapping[option]
                                )
                                so.set(e.identifier, selectedFullNames)
                              } else {
                                so.set(e.identifier, e.selectedOptions)
                              }

                              setAllSelectedOptions(so)
                            }}
                          />
                        </div>
                      ))}
                      <DatePicker
                        disabled={filtersDisabled}
                        range
                        rangeHover
                        dateSeparator=" - "
                        months={[
                          'Enero',
                          'Febrero',
                          'Marzo',
                          'Abril',
                          'Mayo',
                          'Junio',
                          'Julio',
                          'Agosto',
                          'Septiembre',
                          'Octubre',
                          'Noviembre',
                          'Diciembre'
                        ]}
                        weekDays={['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']}
                        format="DD MMM"
                        weekStartDayIndex={1}
                        highlightToday={false}
                        numberOfMonths={2}
                        maxDate={moment().local().endOf('day').toDate()}
                        onChange={(dateObjects) => handleDateFilter(dateObjects)}
                        value={selectedDates}
                        plugins={[
                          <Toolbar
                            position="bottom"
                            names={{
                              deselect: 'Deseleccionar'
                            }}
                            sort={['deselect']}
                          />
                        ]}
                        render={(value, openCalendar) => {
                          return (
                            <div
                              key={`flr-date`}
                              className={`mr-10 ${filtersDisabled ? 'react-filter-disabled' : ''}`}
                            >
                              <div className="react-filter-box filter-input-width-datepicker">
                                <div
                                  title="Rango de fechas"
                                  onClick={!filtersDisabled ? openCalendar : undefined}
                                  className="react-filter-box-clickable-zone"
                                >
                                  <div className="react-filter-titles">
                                    {value?.length > 0 && (
                                      <>
                                        {Array.isArray(value) ? (
                                          <div className="filter-title-overflow">
                                            {value.join(' - ')}
                                          </div>
                                        ) : (
                                          <div className="filter-title-overflow">{value}</div>
                                        )}
                                        <FontAwesomeIcon
                                          style={{ color: 'ef823a' }}
                                          icon={faCalendar}
                                        />
                                      </>
                                    )}
                                    {value?.length == 0 && (
                                      <>
                                        <div className="filter-title-overflow">RANGO DE FECHAS</div>
                                        <FontAwesomeIcon
                                          style={{ color: filtersDisabled ? '#D5D5D5' : '#597fa9' }}
                                          icon={faCalendar}
                                        />{' '}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }}
                      />
                    </div>

                    <div onKeyDown={handleSearch} className="search-input-containter ">
                      <SearchInput
                        query={query}
                        onSearch={(newQuery) => {
                          setQuery(newQuery)
                          fetchSettlementFromServer()
                        }}
                        style={{ fontSize: 12 }}
                        placeholder="Buscar por código o nombre del usuario"
                      />
                    </div>
                  </div>
                  <div className="mb-20">
                    {(filterQuery || dateQuery || searchQuery) && (
                      <a onClick={resetFilters} className="reset-filter cursor-pointer mb-20">
                        Restablecer filtros aplicados
                      </a>
                    )}
                  </div>
                  <Table hover responsive>
                    <thead className="table-header-gray">
                      <tr>
                        <th style={{ verticalAlign: 'middle', padding: '10px 25px', width: '25%' }}>
                          <span className="th-header-text">FECHA Y HORA</span>
                        </th>
                        <th style={{ verticalAlign: 'middle', width: '25%' }}>
                          <span className="th-header-text"># DE LIQUIDACIÓN</span>
                        </th>
                        <th style={{ verticalAlign: 'middle', width: '25%' }}>
                          <span className="th-header-text">CREADO POR</span>
                        </th>
                        <th style={{ verticalAlign: 'middle', textAlign: 'center', width: '25%' }}>
                          <span className="th-header-text">TOTAL COBRADO</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(dataFilter?.length > 0 ? dataFilter : data)?.map((model, index) => (
                        <tr
                          onClick={() => showModel(model.uuid)}
                          className="cursor-pointer"
                          key={index}
                        >
                          <td className="td-text-limited td-text-limited-space">
                            {getTimestampFromDateTime(model.collected_at)}
                          </td>
                          <td className="td-text-limited">{model.uid}</td>
                          <td className="td-text-limited">
                            {model.user_uid} - {model.user_full_name}
                          </td>
                          <td className="td-text-limited centered-text">
                            {formatMoney(model.total_amount)}
                          </td>
                        </tr>
                      ))}
                      {dataFilter?.length === 0 && data?.length === 0 && (
                        <tr>
                          <td colSpan="5" className="td-text-limited td-text-limited-space">
                            No hay resultados
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
              <nav className="index-table">
                {totalPages > 1 && (
                  <Pagination current_page={currentPage} total_pages={totalPages} />
                )}
              </nav>
            </div>
          )}
        </div>
      </div>
      <div>
        <NavBar activeItem="Liquidaciones" isEditing={false} collapsed={collapsed} />
      </div>
    </div>
  )
}

export default SettlementsIndex
