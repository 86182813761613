import React from 'react'
import { formatMoney, parseDocumentType } from '../../helpers/utils'
import { Table } from 'react-bootstrap'

export default class ChargeDetailsTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activity: this.props.activity || []
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ activity: nextProps.activity })
  }

  getFormattedDate = (createdDate) => {
    if (!createdDate) return 'N/A'
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  getFormattedDateOnly = (createdDate) => {
    if (!createdDate) return 'N/A'
    const d = new Date(createdDate)
    return d.toLocaleDateString()
  }

  calculatePaymentsTotal = () => {
    let total = 0
    this.state.activity?.payment_methods?.map((obj) => (total += parseFloat(obj.amount)))
    return total
  }

  getWeight = (_weight = 0) => {
    let weight = Number(_weight).toFixed(2)
    if (weight == 0) return 'N/A'
    return weight
  }

  getTotalDiscount = () => {
    let discountTotal = 0
    this.state.activity.details.map((obj) => {
      const discountPrice = obj.discount_price ? obj.discount_price : 0
      return (discountTotal += parseFloat(discountPrice))
    })
    return discountTotal
  }

  getTotalWeight = () => {
    let tw = Number(this.state.activity['total_weight']).toFixed(2)
    if (tw == 0) return 'N/A'
    return tw
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          {this.props.transactionUuid && (
            <>
              <div className="row show-product-header">
                <div className="col-sm-6">
                  <h3 className="margin-text-subtitle">Detalles de cobro:</h3>
                </div>
              </div>
              <hr />
            </>
          )}
          <div>
            <div className="show-product-header-ubiqua-app show-header">
              <div>
                <div className="order-client-name">{this.state.activity['client_name']}</div>
                <div className="order-client-db_ref">
                  Código: {this.state.activity['client_db_ref']}
                </div>
              </div>
              {/* {this.state.activity?.origin == 'UbiquaApp' && (
                <button className="float-right download-order-button" onClick={this.download}>
                  Descargar cobro
                  <FontAwesomeIcon icon={faArrowCircleDown} />
                </button>
              )} */}
            </div>
            <hr />
            <div>
              <table cellSpacing="10">
                <tbody>
                  <tr>
                    <td>
                      <div className="mr-85 mb-10">
                        <b>Fecha de creación:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">
                        {this.getFormattedDate(this.state.activity['created_date'])}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-10">
                        <b>Número de cobro:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">{this.state.activity['charge_db_ref']}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-85 mb-10">
                        <b>Vendedor:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">{this.state.activity['salesman_name']}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <div className="show-data-details">
                <div>
                  <b>Métodos de pago:</b>
                </div>
              </div>

              <Table hover responsive>
                <thead className="table-header-gray">
                  <tr>
                    <th>TIPO DE PAGO</th>
                    <th>FECHA</th>
                    <th>BANCO</th>
                    <th>NÚMERO DE REFERENCIA</th>
                    <th>MONTO</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.activity['payment_methods'].map((model, index) => (
                    <tr key={index}>
                      <td>{model['payment_type']}</td>
                      <td>{this.getFormattedDateOnly(model['date'])}</td>
                      <td>{model['bank_name'] || 'N/A'}</td>
                      <td>{model['reference'] || 'N/A'}</td>
                      <td>{formatMoney(model['amount'])}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className="totals-container">
                <div className="totals-card">
                  <div className="totals-line">
                    <div>
                      <h4>
                        <b style={{ whiteSpace: 'nowrap' }}>
                          TOTAL PAGADO POR EL CLIENTE: {formatMoney(this.calculatePaymentsTotal())}
                        </b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="show-data-details">
                <div>
                  <b>Documentos:</b>
                </div>
              </div>

              <Table hover responsive>
                <thead className="table-header-gray">
                  <tr>
                    <th>NÚMERO DE DOCUMENTO</th>
                    <th>FECHA</th>
                    <th>TIPO</th>
                    <th>SALDO</th>
                    <th>PAGADO</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.activity['details'].map((model, index) => (
                    <tr key={index}>
                      <td>
                        <a
                          className="ubiqua-link"
                          target="_blank"
                          href={`/entregas/${model['document_reference']}`}
                        >
                          {model['document_reference']}
                        </a>
                      </td>
                      <td>{this.getFormattedDateOnly(model['document_date'])}</td>
                      <td>{parseDocumentType(model['document_type'])}</td>
                      <td>
                        {model['document_total']
                          ? formatMoney(model['document_total'])
                          : formatMoney(model['pending_balance'])}
                      </td>
                      <td>{formatMoney(model['document_amount'])}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className="totals-container">
                <div className="totals-card">
                  <div className="totals-line">
                    <div>
                      <h4>
                        <b style={{ whiteSpace: 'nowrap' }}>
                          TOTAL DOCUMENTOS APLICADOS:{' '}
                          {formatMoney(this.state.activity?.total_amount)}
                        </b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
