export const STATUS_OPTIONS = [
  { name: 'POR ENTREGAR', value: 'open' },
  { name: 'ENTREGADO', value: 'delivery_receipt' },
  { name: 'RECHAZADO', value: 'refund' },
  { name: 'PARCIAL', value: 'partial' }
]

export const DATE_PICKER_SETTINGS = {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  weekDays: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
  weekStartDayIndex: 1
}
