import React, { useState, useEffect } from 'react'
import cookie from 'react-cookies'
import {
  capitalizeFirstLetterFrom,
  effectiveLocationWithAllowedLocation,
  formatMoney
} from '../../helpers/utils'
import { Table } from 'react-bootstrap'
import moment from 'moment'

const DeliveryDetailsTab = ({ activity: initialActivity = [], transactionUuid }) => {
  const [activity, setActivity] = useState(initialActivity)
  const [taxSettings] = useState(cookie.load('tax_settings'))

  useEffect(() => {
    setActivity(initialActivity)
  }, [initialActivity])

  const getFormattedDate = (date) => {
    return date ? new Date(date).toLocaleString() : '-'
  }

  const calculateSubTotal = () => {
    return (
      activity.details?.reduce((total, obj) => {
        const calculatedTaxPrice = obj.calculated_tax_price || obj.extended_price
        return total + parseFloat(calculatedTaxPrice)
      }, 0) || 0
    )
  }

  const customFieldsArray = Array.isArray(activity.custom_fields) ? activity.custom_fields : [];

  const tableRows = customFieldsArray.length
    ? customFieldsArray.map(({ label, value }) => ({
        label: label || '-',
        value: value || '-',
      }))
    : [
        { label: 'Fecha de documento', value: getFormattedDate(activity.collected_at || activity.created_at) },
        { label: 'Fecha de asignación', value: activity.visit_date ? moment(activity.visit_date).format('DD/MM/YYYY') : '-' },
        { label: 'Fecha de ejecución', value: getFormattedDate(activity.created_date || activity.collected_at) },
        { label: 'Número de entrega', value: activity.status === 'open' ? '-' : activity.uid || '-' },
        { label: 'Vendedor', value: activity.salesman_db_ref && activity.salesman_name ? `${activity.salesman_db_ref} - ${activity.salesman_name}` : '-' },
        { label: 'Tipo de cliente', value: activity.status === 'open' ? (activity.delivery_type === 'E' ? 'Factura' : activity.delivery_type === 'C' ? 'Crédito' : '-') : (activity.client_payment_type ? capitalizeFirstLetterFrom(activity.client_payment_type) : '-') },
        { label: 'Número de factura', value: activity.delivery_reference || '-' },
        { label: 'Referencia del cobro', value: activity.payment_reference ? <a className="ubiqua-link" target="_blank" href={`/cobros/${activity.payment_reference}`}>{activity.payment_reference}</a> : '-' },
        { label: 'Dentro de geocerca', value: effectiveLocationWithAllowedLocation(activity.effective_location, activity.has_allowed_location) || '-' },
      ].filter(Boolean);

  const extraColumns = new Set();
  const processedDetails = activity.details?.map((product) => {
    let customFieldValues = {};
  
    try {
      let customFields = product.custom_fields_item;
      if (typeof customFields === "string" && customFields.trim() !== "") {
        customFields = JSON.parse(customFields);
      }
      if (Array.isArray(customFields)) {
        customFields.forEach(({ label, value }) => {
          extraColumns.add(label);
          customFieldValues[label] = value ?? "-"; 
        });
      }
    } catch (error) {
      console.error("Error parsing custom_fields_item:", error);
    }
  
    return { ...product, customFieldValues };
  }) || [];

  return (
    <div className="container-fluid">
      {(transactionUuid || activity.uid) && (
        <>
          <div className="row show-product-header">
            <div className="col-sm-6">
              <h3 className="margin-text-subtitle">Detalles de entrega:</h3>
            </div>
          </div>
          <hr />
        </>
      )}

      <div className="show-product-header-ubiqua-app show-header">
        <div>
          <div className="order-client-name">{activity.client_name}</div>
          <div className="order-client-db_ref">Código: {activity.client_db_ref}</div>
        </div>
      </div>
      <hr />

      <table cellSpacing="10">
        <tbody>
          {tableRows.map(({ label, value }, idx) => (
            <tr key={idx}>
              <td>
                <div className="mr-85 mb-10">
                  <b>{label}:</b>
                </div>
              </td>
              <td>
                <div className="mb-10">{value}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr />

      <div className="show-data-details">
        <div>
          <b>Productos:</b>
        </div>
      </div>

      <Table hover responsive>
        <thead className="table-header-gray">
          <tr>
            <th>CÓDIGO</th>
            <th>NOMBRE DEL PRODUCTO</th>
            <th>UNIDAD DE VENTA</th>
            <th>CANTIDAD</th>
            <th>PRECIO</th>
            <th className="text-center">SUB-TOTAL</th>
            {[...extraColumns].map((col, index) => (
              <th key={`col-${index}`}className="column-custom">{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {processedDetails.map((model, index) => (
            <tr key={index}>
              <td>{model.product_db_ref}</td>
              <td>{model.product_name}</td>
              <td>{model.unit}</td>
              <td>{parseInt(model.quantity)}</td>
              <td>{formatMoney(Number(model.price))}</td>
              <td className="text-center">{formatMoney(Number(model.extended_price))}</td>
              {[...extraColumns].map((col, idx) => (
                <td key={`val-${index}-${idx}`}className="column-custom">{model.customFieldValues[col] || '-'}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="totals-container-delivery">
        <div className={`totals-card ${!activity.signature_name ? 'hidden-content' : ''}`}>
          <div className="totals-line">
            <div>
              <b>Recibido por:</b>
            </div>
            <div>{activity.signature_name}</div>
          </div>
          {activity.signature_b64 && (
            <img width={400} src={`data:image/bmp;base64,${activity.signature_b64}`} alt="Firma" />
          )}
        </div>
        <div className="totals-card">
          <div className="totals-line">
            <div>
              <b>SUB-TOTAL:</b>
            </div>
            <div>{formatMoney(activity.subtotal || calculateSubTotal())}</div>
          </div>
          {taxSettings.tax_rate_2 && (
            <div className="totals-line">
              <div>
                <b>{taxSettings.tax_rate_2}:</b>
              </div>
              <div>{formatMoney(activity.taxes_2 || 0)}</div>
            </div>
          )}
          <div className="totals-line">
            <div>
              <b>{taxSettings.tax_rate}:</b>
            </div>
            <div>{formatMoney(activity.taxes || 0)}</div>
          </div>
          <div className="totals-line">
            <div>
              <h4>
                <b>TOTAL:</b>
              </h4>
            </div>
            <div>{formatMoney(activity.total)}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeliveryDetailsTab
