import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import axios from 'axios'
import cookie from 'react-cookies'
import { SPANISH } from 'rrule'
import { fixDateString, getDay, getMonth } from '../../helpers/formatDate'
import { replaceAccentedCharacters } from '../../helpers/utils'
import TaskDescription from './components/TaskDescription'
import TaskClientOrAttribute from './components/TaskClientOrAttribute'
import TaskScheduleConfig from './components/TaskScheduleConfig'
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'

let countsCache = {
  brand: {},
  category: {}
}

const InventoryCaptureTask = ({
  setCloningTask,
  cloningTask,
  setFailedCreation,
  failedCreation,
  taskData,
  readyToCollect,
  setSaving,
  formDataCollector,
  isStep3Completed,
  setIsStep3Completed
}) => {
  const [description, setDescription] = useState('')
  const [clientOrAttribute, setClientOrAttribute] = useState('')
  const [clients, setClients] = useState([])
  const [products, setProducts] = useState([])
  const [brand, setBrand] = useState('')
  const [category, setCategory] = useState('')
  const [productSelection, setProductSelection] = useState('')
  const [qualifiers, setQualifiers] = useState({
    qualifier_2: [],
    qualifier_3: [],
    qualifier_4: [],
    qualifier_5: []
  })
  const [qualifierA, setQualifierA] = useState([])
  const [qualifierB, setQualifierB] = useState([])
  const [qualifierC, setQualifierC] = useState([])
  const [users, setUsers] = useState([])
  const [userOptions, setUserOptions] = useState(null)
  const [userFilterChecked, setUserFilterChecked] = useState(false)
  const [productsBase, setProductsBase] = useState({})
  const [productCount, setProductCount] = useState('...')
  const [unitOfSale, setUnitOfSale] = useState('')
  const [unitOfSale1Count, setUnitOfSale1Count] = useState(0)
  const [unitOfSale2Count, setUnitOfSale2Count] = useState(0)
  const [isUnitOfSale1Available, setIsUnitOfSale1Available] = useState(false)
  const [isUnitOfSale2Available, setIsUnitOfSale2Available] = useState(false)
  const [newStartTime, setNewStartTime] = useState(null)
  const [newEndTime, setNewEndTime] = useState(null)
  const [recurrenceRule, setRecurrenceRule] = useState(null)

  const productSelectionType = [
    { value: '', text: 'Escoja una opción', hidden: true },
    { value: 'brand', text: 'Marca' },
    { value: 'category', text: 'Categoría' },
    { value: 'individual_products', text: 'Producto individual' }
  ]

  const isUnitOfSale1Valid =
    (Number(productCount) === unitOfSale1Count && unitOfSale1Count > 0) ||
    unitOfSale === 'unit_of_sale_1' ||
    isUnitOfSale1Available
  const isUnitOfSale2Valid =
    (Number(productCount) === unitOfSale2Count && unitOfSale2Count > 0) ||
    unitOfSale === 'unit_of_sale_2' ||
    isUnitOfSale2Available
  const isSelectDisabled =
    (!isUnitOfSale1Valid && !isUnitOfSale2Valid) || Number(productCount) === 0

  const openUrlNewWindow = (url) => {
    window.open(url, '_blank')
  }

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  var abortClientController = new AbortController()
  var abortProductController = new AbortController()
  const abortCurrentFetchs = () => {
    abortClientController.abort()
    abortProductController.abort()
    abortController.abort()
  }
  window.addEventListener('beforeunload', abortCurrentFetchs)
  //////

  // Stops client fetch when is unfocus
  const abortClientFetchs = () => {
    abortClientController.abort()
  }
  //

  // Stops products fetch when is unfocus
  const abortProductFetchs = () => {
    abortProductController.abort()
  }
  //

  const handleRecurrenceRuleChange = (newRule) => {
    setRecurrenceRule(newRule)
  }
  const handleStartTimeChange = (newStartTime) => {
    setNewStartTime(newStartTime)
  }

  const handleEndTimeChange = (newEndTime) => {
    setNewEndTime(newEndTime)
  }

  const handleChangeQualifierA = (event) => {
    if (event != null) {
      setQualifierA(event?.map((e) => e.value))
    }
  }

  const handleChangeQualifierB = (event) => {
    if (event != null) {
      setQualifierB(event?.map((e) => e.value))
    }
  }

  const handleChangeQualifierC = (event) => {
    if (event != null) {
      setQualifierC(event?.map((e) => e.value))
    }
  }

  const checkUnitsOfSaleAvailability = () => {
    const hasUnitOfSale1 =
      products.length > 0 &&
      products.every(
        (product) =>
          product.packages[0] && product.packages[0].unit && product.packages[0].unit !== '-'
      )
    const hasUnitOfSale2 =
      products.length > 0 &&
      products.every(
        (product) =>
          product.packages[1] && product.packages[1].unit && product.packages[1].unit !== '-'
      )
    setIsUnitOfSale1Available(hasUnitOfSale1)
    setIsUnitOfSale2Available(hasUnitOfSale2)
  }

  useEffect(() => {
    checkUnitsOfSaleAvailability()
  }, [products])

  useEffect(() => {
    const isStep3Completed =
      clients.length > 0 || qualifierA.length > 0 || qualifierB.length > 0 || qualifierC.length > 0

    setIsStep3Completed(isStep3Completed)
  }, [clients, qualifierA, qualifierB, qualifierC])

  const handleProductSelectionChange = (event) => {
    setProductSelection(event.target.value)
    setProducts([])
    setUnitOfSale('')
    setBrand(null)
    setCategory(null)
  }

  const isAnyKindOfProductSelected = () => {
    if (products?.length > 0 || brand || category) {
      return true
    }
    return false
  }

  const isClientOrSomeQualifierSelected = () => {
    if (clients?.length > 0 || isSomeQualifierSelected()) {
      return true
    }
    return false
  }

  const isSomeQualifierSelected = () => {
    if (qualifierA?.length > 0 || qualifierB?.length > 0 || qualifierC?.length > 0) {
      return true
    }
    return false
  }

  const handleClientOrAttributeChange = (event) => {
    setClientOrAttribute(event.target.value)
    setClients([])
    setQualifierA([])
    setQualifierB([])
    setQualifierC([])
    setUserFilterChecked(false)
    setUsers([])
  }

  const handleUnitOfSaleChange = (event) => {
    setUnitOfSale(event.target.value)
  }

  const handleBrandsChange = (event) => {
    if (event?.brand) {
      setBrand(event.brand)
      setUnitOfSale('')
      fetchDataFromServerPackage(event.brand.db_ref, event.brand.name)
    }
  }

  const handleCategoryChange = (event) => {
    if (event?.category) {
      setCategory(event.category)
      setUnitOfSale('')
      fetchDataFromServerPackage('', '', event.category.db_ref, event.category.description)
    }
  }

  const truncatePreviewText = (text) => {
    if (text?.length > 15) {
      return text.substring(0, 15) + '...'
    }
    return text
  }

  const getClient = async (inputValue) => {
    if (inputValue.length >= 3) {
      abortClientController = new AbortController()
      return fetch(
        `${BACKEND_URL}/businesses/${cookie.load('business_id')}/clients?q=${inputValue}&active=true`,
        {
          method: 'GET',
          signal: abortClientController.signal,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        }
      )
        .then((response) => response.json())
        .then((data) =>
          data.table.map((cl) => ({
            value: cl.name,
            label: `${cl.name} - ${cl.db_ref}`,
            client: cl
          }))
        )
        .catch((error) => {
          if (error.name != 'AbortError') {
            console.error('Error fetching clients:', error)
            return []
          }
        })
    }
  }

  const getQualifiers = () => {
    const url = USE_BUSINESS_URL
      ? `${BACKEND_URL}/businesses/${cookie.load('business_id')}/promo_qualifiers`
      : `${BACKEND_URL}/promo_qualifiers`

    fetch(url, {
      method: 'GET',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setQualifiers(data.result[0])
      })
      .catch((e) => console.log(e))
  }

  const getUsers = () => {
    fetch(
      `${BACKEND_URL}/businesses/${cookie.load('business_id')}/ubiqua_app_users?non_paginated=true`,
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setUserOptions(
          data.table.map((u) => ({
            value: u.full_name,
            label: `${u.full_name} - ${u.uid}`,
            user: u
          }))
        )
      })
      .catch((e) => console.log(e))
  }

  const getUserForCloning = async (uid) => {
    return fetch(
      `${BACKEND_URL}/businesses/${cookie.load('business_id')}/ubiqua_app_users/${uid}?active=true`,
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        return {
          value: data.full_name,
          label: `${data.full_name} - ${data.uid}`,
          user: data
        }
      })
      .catch((e) => console.log(e))
  }

  const displayProductCount = (product_count) => {
    if (product_count == '...') {
      return 'Cargando...'
    }
    let brandCountNum = Number(product_count)
    return isNaN(brandCountNum)
      ? '...'
      : `${brandCountNum} - ${
          brandCountNum > 1 || brandCountNum == 0 ? 'PRODUCTOS ACTIVOS' : 'PRODUCTO ACTIVO'
        }`
  }

  const validateAtLeastOneQualifier = (_qualifierA, _qualifierB, _qualifierC) => {
    if (_qualifierA?.length > 0 || _qualifierB?.length > 0 || _qualifierC?.length > 0) {
      return 'success'
    }
    return null
  }

  const getProductsCount = async () => {
    let type = ''
    let typeData = null
    if (category) {
      type = 'category'
      typeData = category.db_ref
    }
    if (brand) {
      type = 'brand'
      typeData = brand.db_ref
    }
    if (typeData) {
      if (countsCache[type][typeData]) {
        setProductCount(countsCache[type][typeData])
      } else {
        setProductCount('...')
        let resp = await fetch(
          `${BACKEND_URL}/businesses/${cookie.load('business_id')}/products?by_${type}=${typeData}&count_only=true`,
          {
            method: 'GET',
            signal: abortController.signal,
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + cookie.load('token')
            }
          }
        )
        if (resp.status != 200) {
          console.error('Error al obtener el conteo de productos')
          setProductCount(0)
        }

        let data = await resp.json()
        countsCache[type][typeData] = data.result
        setProductCount(data.result)
      }
    } else {
      setProductCount('...')
    }
  }

  const clearProductBrandOrCategory = () => {
    setBrand(null)
    setCategory(null)
  }

  const fetchDataFromServerPackage = (
    byBrand = '',
    brandName = '',
    byCategory = '',
    categoryName = ''
  ) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${BACKEND_URL}/businesses/${cookie.load('business_id')}/products?page=1` +
          `${byBrand ? '&by_brand=' + byBrand : ''}` +
          `${byCategory ? '&by_category=' + byCategory : ''}` +
          '&package_only=true',
        {
          method: 'GET',
          signal: abortController.signal,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setUnitOfSale1Count(data.total_unidad_de_venta1)
          setUnitOfSale2Count(data.total_unidad_de_venta2)
          resolve(data)
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
          reject(error)
        })
    })
  }

  const getProductsBaseData = () => {
    fetch(`${BACKEND_URL}/businesses/${cookie.load('business_id')}/products/promo_necessary_data`, {
      method: 'GET',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setProductsBase(data)
      })
      .catch((e) => console.log(e))
  }

  const qualifierAOptions =
    qualifiers?.qualifier_2?.map((value) => ({
      value: value,
      label: value,
      qualifier_2: value
    })) || []

  const qualifierBOptions =
    qualifiers?.qualifier_3?.map((value) => ({
      value: value,
      label: value,
      qualifier_3: value
    })) || []

  const qualifierCOptions =
    qualifiers?.qualifier_4?.map((value) => ({
      value: value,
      label: value,
      qualifier_4: value
    })) || []

  const getProducts = async (inputValue) => {
    if (inputValue.length >= 3) {
      abortProductController = new AbortController()
      return fetch(
        `${BACKEND_URL}/businesses/${cookie.load('business_id')}/products?q=${replaceAccentedCharacters(
          inputValue
        )}&productactive=true`,
        {
          method: 'GET',
          signal: abortProductController.signal,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then((data) => {
          return data.table.map((p) => ({
            value: p.name,
            label: `${p.name} - ${p.db_ref}`,
            product: p
          }))
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }

  const handleClientOption = (options) => {
    setClients(options)
  }

  const handleUsersChange = (options) => {
    setUsers(options)
  }

  const handleProductOption = (options) => {
    if (!options?.product) {
      return
    }
    let alreadyExist = products.find((e) => e.id === options.product.id)
    if (alreadyExist) {
      return
    }
    setProducts([...products, options.product])
  }

  const sleep = (ms) =>
    // eslint-disable-next-line no-undef
    new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })

  const getTaskQuantity = () => {
    let taskQuantity = 0
    if (products?.length > 0) {
      taskQuantity = products.length
    }
    if (productCount != '...' && !isNaN(productCount)) {
      taskQuantity = productCount
    }
    return taskQuantity
  }

  const collectData = () => {
    let rule = recurrenceRule
    let jsonData = {
      description: description,
      recurrence_format: rule ? rule.toString() : 'null',
      recurrence_interval: rule ? rule.options.interval : null,
      recurrence_description: rule.toText(SPANISH),
      start_date: fixDateString(newStartTime),
      end_date: fixDateString(newEndTime),
      qualifier_1: clients.map((c) => c.client.db_ref).sort(),
      qualifier_2: qualifierA,
      qualifier_3: qualifierB,
      qualifier_4: qualifierC,
      user_uids: userFilterChecked ? users.map((u) => u.user.uid).sort() : [],
      user_names: userFilterChecked ? users.map((u) => u.label).sort() : [],
      products_selection_type: productSelection,
      category_db_ref: category?.db_ref,
      category_name: category?.description,
      brand_db_ref: brand?.db_ref,
      brand_name: brand?.name,
      products_db_ref: products.map((p) => p.db_ref).sort(),
      product_names: products.map((p) => p.db_ref + ' ' + p.name).sort(),
      unit_of_sale: unitOfSale,
      task_quantity: getTaskQuantity()
    }
    formDataCollector(jsonData)
  }

  const setUpTask = async () => {
    if (taskData) {
      try {
        setCloningTask(true)
        setDescription(taskData.description)

        if (taskData.client_names?.length > 0) {
          setClientOrAttribute('client')
          let _clients = []
          for (const clientName of taskData.qualifier_1) {
            let c = await getClient(clientName)
            if (c?.length > 0) {
              _clients.push(c[0])
            }
          }
          setClients(_clients)
        } else {
          setClientOrAttribute('qualifier')
          if (qualifierAOptions?.length > 0) {
            setQualifierA(taskData.qualifier_2)
          }
          if (qualifierBOptions?.length > 0) {
            setQualifierB(taskData.qualifier_3)
          }
          if (qualifierCOptions?.length > 0) {
            setQualifierC(taskData.qualifier_4)
          }
        }

        if (taskData.user_uids?.length > 0) {
          let _users = []
          for (const uid of taskData.user_uids) {
            let user = await getUserForCloning(uid)
            if (user) {
              _users.push(user)
            }
          }
          setUsers(_users)
          setUserFilterChecked(true)
        }
      } catch (e) {
        console.log('Error en duplicacion de tarea  (Clientes/Atributos/Usuarios): ', e)
        setFailedCreation(
          'Hubo un error al intentar obtener: Los clientes o atributos o usuarios, requeridos para crear la copia de la tarea. Por favor, asegúrese de completar todos los campos faltantes en adelante.'
        )
        setTimeout(() => setFailedCreation(false), 15000)
        return setCloningTask(false)
      }
      try {
        switch (taskData.products_selection_type) {
          case 'individual_products':
            {
              setProductSelection('individual_products')
              let _products = []
              for (const productDbRef of taskData.products_db_ref) {
                let p = await getProducts(productDbRef)
                if (p?.length > 0) {
                  _products.push(p[0]?.product)
                }
              }
              let cleanProducts = _products.filter(
                (value, index, self) => index === self.findIndex((t) => t.db_ref === value.db_ref)
              )
              setProducts(cleanProducts)

              if (
                (isUnitOfSale1Available && taskData.unit_of_sale === 'unit_of_sale_1') ||
                (isUnitOfSale2Available && taskData.unit_of_sale === 'unit_of_sale_2')
              ) {
                setUnitOfSale(taskData.unit_of_sale)
              }
            }
            break
          case 'brand':
            {
              setProductSelection('brand')
              let _brand = productsBase?.brand?.find((b) => b.db_ref === taskData.brand_db_ref)
              setBrand(_brand)
              fetchDataFromServerPackage(taskData.brand_db_ref, taskData.brand_name).then(() => {
                if (Number(productCount) === unitOfSale1Count && unitOfSale1Count > 0) {
                  setUnitOfSale(taskData.unit_of_sale)
                }
              })
            }
            break
          case 'category':
            {
              setProductSelection('category')
              let _category = productsBase?.categories?.find(
                (c) => c.db_ref === taskData.category_db_ref
              )
              setCategory(_category)
              fetchDataFromServerPackage(
                '',
                '',
                taskData.category_db_ref,
                taskData.category_name
              ).then(() => {
                if (Number(productCount) === unitOfSale1Count && unitOfSale1Count > 0) {
                  setUnitOfSale(taskData.unit_of_sale)
                }
              })
            }
            break
        }
      } catch (e) {
        console.log('Error en duplicacion de tarea  (Productos/Marcas/Categorias): ', e)
        setFailedCreation(
          'Hubo un error al intentar obtener: Productos/Marcas/Categorias, requeridos para crear la copia de la tarea. Por favor, asegúrese de completar todos los campos faltantes en adelante.'
        )
        setTimeout(() => setFailedCreation(false), 15000)
        return setCloningTask(false)
      }
      setCloningTask(false)
      await sleep(1000)
      setSaving(false)
    }
  }

  useEffect(() => {
    getQualifiers()
    getUsers()
    getProductsBaseData()
  }, [])

  useEffect(() => {
    if (taskData) {
      setUpTask()
    }
  }, [taskData, productsBase.brand, productsBase.categories])

  useEffect(() => {
    getProductsCount()
  }, [category, brand])

  useEffect(() => {
    if (readyToCollect) {
      collectData()
    }
  }, [readyToCollect])

  if (cloningTask) {
    return null
  }
  return (
    <>
      <TaskDescription initialDescription={description} onDescriptionChange={setDescription} />

      <TaskClientOrAttribute
        clientOrAttribute={clientOrAttribute}
        handleClientOrAttributeChange={handleClientOrAttributeChange}
        clients={clients}
        handleClientOption={handleClientOption}
        getClient={getClient}
        abortClientFetchs={abortClientFetchs}
        qualifierA={qualifierA}
        handleChangeQualifierA={handleChangeQualifierA}
        qualifierAOptions={qualifierAOptions}
        qualifierB={qualifierB}
        handleChangeQualifierB={handleChangeQualifierB}
        qualifierBOptions={qualifierBOptions}
        qualifierC={qualifierC}
        handleChangeQualifierC={handleChangeQualifierC}
        qualifierCOptions={qualifierCOptions}
        validateAtLeastOneQualifier={validateAtLeastOneQualifier}
        description={description}
        isClientOrSomeQualifierSelected={isClientOrSomeQualifierSelected}
        userFilterChecked={userFilterChecked}
        setUserFilterChecked={setUserFilterChecked}
        users={users}
        userOptions={userOptions}
        handleUsersChange={handleUsersChange}
      />
      <div className="promo-form-section">
        <div className="title-promos-form">
          <b class="bold-step">PASO 4: Escoja los productos que aplican a esta tarea</b>
        </div>
        <hr className="hr-promos-form" />
        <p>Por marca, categoría o productos individuales</p>
        <div className="form-control-width ">
          <select
            className={`select-promo-type form-control custom-select form-control-width ${
              !isClientOrSomeQualifierSelected() && `inactive`
            }`}
            name="taskType"
            value={productSelection}
            onChange={handleProductSelectionChange}
            disabled={!isClientOrSomeQualifierSelected()}
            required
          >
            {productSelectionType?.map((option) => (
              <option key={option.value + 1} value={option.value} hidden={option.hidden}>
                {option.text}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-50" />
      </div>

      {productSelection === 'individual_products' && (
        <div className="promo-form-section">
          <div className="title-promos-form">
            <p>
              <b>PASO 4.1: Escoja los productos que aplican a esta tarea</b>
            </p>
          </div>
          <div className="form-control-width">
            <div className="fake-validator-container">
              <input
                className="input-fake-validator"
                type="text"
                required
                value={products.length >= 1 ? products : null}
              />
              <AsyncSelect
                className="clients"
                placeholder={'Escribir nombre o código del producto'}
                noOptionsMessage={() => 'No hay resultados'}
                loadingMessage={() => 'Cargando productos...'}
                name="selectOptionProducts"
                loadOptions={getProducts}
                isClearable={true}
                searchParamName={'q'}
                isSearchable={true}
                value={[]}
                onChange={(e, am) => {
                  handleProductOption(e)
                  am.action = 'clear'
                }}
                onBlur={abortProductFetchs}
              />
            </div>
          </div>
          {products?.length > 0 && (
            <div className="promo-form-section row">
              <table className="table-pdv table-hover promos-product-table">
                <thead className="table-header-pdv">
                  <tr>
                    <th className="pl-20">CÓDIGO</th>
                    <th>NOMBRE DEL PRODUCTO</th>
                    <th>UNIDAD DE VENTA 1</th>
                    <th>UNIDAD DE VENTA 2</th>
                    <th className="centered">ACCIÓN</th>
                  </tr>
                </thead>
                <tbody>
                  {products?.map((product, index) => (
                    <tr key={`${product.id}-${index}`}>
                      <td className="pl-20">{product.db_ref}</td>
                      <td>{product.name}</td>
                      <td>
                        {product.packages[0]?.unit && (
                          <>
                            {product.packages[0]?.unit}{' '}
                            {product.packages[0]?.quantity > 1
                              ? `de ${product.packages[0]?.quantity}`
                              : ''}
                          </>
                        )}
                        {!product.packages[0]?.unit && '-'}
                      </td>
                      <td>
                        {product.packages[1]?.unit && (
                          <>
                            {product.packages[1]?.unit}{' '}
                            {product.packages[1]?.quantity > 1
                              ? `de ${product.packages[1]?.quantity}`
                              : ''}
                          </>
                        )}
                        {!product.packages[1]?.unit && '-'}
                      </td>
                      <td
                        className="delete-selected-product"
                        onClick={(e) => setProducts(products.filter((p) => p.id !== product.id))}
                      >
                        &times;
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}

      {productSelection === 'brand' && (
        <div className="promo-form-section">
          <div className="title-promos-form">
            <p>
              <b>PASO 4.1: Seleccione la marca de los productos para esta tarea</b>
            </p>
          </div>
          <div className="form-control-width">
            <div className="fake-validator-container">
              <input
                className="input-fake-validator"
                type="text"
                required
                value={brand?.db_ref ? brand?.db_ref : null}
              />
              <Select
                isDisabled={brand ? true : false}
                placeholder={'Escoja una opcion'}
                noOptionsMessage={() => 'No hay resultados'}
                loadingMessage={() => 'Cargando...'}
                className="qualifier"
                isClearable={false}
                value={brand ? { value: brand.db_ref, label: brand.name } : null}
                onChange={(e) => handleBrandsChange(e)}
                options={productsBase?.brand?.map((option) => {
                  return { value: option.db_ref, label: option.name, brand: option }
                })}
              />
            </div>
          </div>
          {isAnyKindOfProductSelected() && (
            <div className="box-products-preview-container-pos mt-20">
              <div className="box-products-preview-pos mr-10">
                <p className="m-0" title={brand?.name}>
                  {displayProductCount(productCount)}
                </p>
                <div onClick={clearProductBrandOrCategory} className="delete-selected-product">
                  &times;
                </div>
              </div>
              <button
                type="button"
                onClick={() =>
                  openUrlNewWindow(`/productos?by_brand=${brand.db_ref}&brand_name=${brand.name}`)
                }
                className="big-btn-tasks"
              >
                Ver lista de productos
              </button>
            </div>
          )}
          <div className="mb-50" />
        </div>
      )}
      {productSelection === 'category' && (
        <div className="promo-form-section">
          <div className="title-promos-form">
            <p>
              <b>PASO 4.1: Seleccione la categoría de los productos para esta tarea</b>
            </p>
          </div>
          <div className="form-control-width">
            <div className="fake-validator-container">
              <input
                className="input-fake-validator"
                type="text"
                required
                value={category?.db_ref ? category?.db_ref : null}
              />
              <Select
                isDisabled={category ? true : false}
                placeholder={'Escoja una opcion'}
                noOptionsMessage={() => 'No hay resultados'}
                loadingMessage={() => 'Cargando...'}
                className="qualifier"
                isClearable={true}
                value={category ? { value: category.db_ref, label: category.description } : null}
                onChange={(e) => handleCategoryChange(e)}
                options={productsBase?.categories?.map((option) => {
                  return { value: option.db_ref, label: option.description, category: option }
                })}
              />
            </div>
          </div>
          {isAnyKindOfProductSelected() && (
            <div className="box-products-preview-container-pos mt-20">
              <div className="box-products-preview-pos mr-10">
                <p className="m-0" title={category?.description}>
                  {displayProductCount(productCount)}
                </p>
                <div onClick={clearProductBrandOrCategory} className="delete-selected-product">
                  &times;
                </div>
              </div>
              <button
                type="button"
                onClick={() =>
                  openUrlNewWindow(
                    `/productos?by_category=${category.db_ref}&category_name=${category.description}`
                  )
                }
                className="big-btn-tasks"
              >
                Ver listado de productos
              </button>
            </div>
          )}
          <div className="mb-50" />
        </div>
      )}
      {isAnyKindOfProductSelected() && (
        <>
          <p className="title-promos-form">
            <b>PASO 4.2: Seleccione la unidad de venta de los productos a aplicar en la tarea</b>
          </p>
          <div className="form-control-width">
            <select
              onChange={handleUnitOfSaleChange}
              className="select-promo-type form-control custom-select form-control-width"
              name="base_package_promo"
              required
              value={unitOfSale || ''}
              onClick={(e) => {
                if (isSelectDisabled) {
                  e.preventDefault()
                }
              }}
            >
              <option value="" hidden>
                Escoja una opción
              </option>

              {isUnitOfSale1Valid && (
                <option key={11} value="unit_of_sale_1">
                  {`Unidad de venta 1`}
                </option>
              )}

              {isUnitOfSale2Valid && (
                <option key={12} value="unit_of_sale_2">
                  {`Unidad de venta 2`}
                </option>
              )}
            </select>
            {Number(productCount) == 0 && (
              <p className="warning-text">
                {productSelection === 'brand' &&
                  'La marca seleccionada no tiene productos disponibles. Elige una marca diferente para continuar.'}
                {productSelection === 'category' &&
                  'La categoría seleccionada no tiene productos disponibles. Elige una categoría diferente para continuar.'}
              </p>
            )}
            {isSelectDisabled && Number(productCount) !== 0 && (
              <p className="warning-text">
                Los productos seleccionados carecen de paquetes necesarios. Ver lista de productos.
              </p>
            )}
          </div>
        </>
      )}

      <TaskScheduleConfig
        onRecurrenceChange={handleRecurrenceRuleChange}
        onStartChange={handleStartTimeChange}
        onEndChange={handleEndTimeChange}
      />
    </>
  )
}
export default InventoryCaptureTask
