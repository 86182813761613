import React, { useState, useEffect } from 'react'
import { getTimestampFromDateTime, getCurrentDate } from '../helpers/formatDate'
import { formatMoney, parseDocumentType } from '../helpers/utils'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import cookie from 'react-cookies'
import { CSVLink } from 'react-csv'
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table } from 'react-bootstrap'
import { faFilter, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import fetchWithAuth from '../helpers/fetchWithAuth'
import CashCount from './settlementsdetails/CashCount'
import ReactCheckboxFilter from '../components/inputs/ReactCheckboxFilter'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BACKEND_URL = USE_BUSINESS_URL
  ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : process.env.REACT_APP_BACKEND_URL

function SettlementsDetails() {
  const tabs = ['Detalles', 'Documentos', 'Anulados']
  const [isFetching, setIsFetching] = useState(false)
  const [selectedTab, setSelectedTab] = useState('Detalles')
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const [cashData, setCashData] = useState(null)
  const [cashDataTotales, setCashDataTotales] = useState(null)
  const [collapsed, setCollapsed] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [filteredDetails, setFilteredDetails] = useState([])
  const [allSelectedFilterOptions, setAllSelectedFilterOptions] = useState(null)

  const handleSearch = (event) => {
    setSearchInput(event.target.value)
  }

  const handleEnterSearch = (event) => {
    if (event.key === 'Enter') {
      filterDetails()
    }
  }

  const filterDetails = () => {
    let searchQuery = searchInput.trim().toLowerCase()

    let filtered = []

    if (selectedTab === 'Documentos') {
      filtered = data?.details.filter(
        (detail) =>
          detail.child_document_reference.includes(searchQuery) ||
          detail.charge_uid.includes(searchQuery)
      )
    } else if (selectedTab === 'Anulados') {
      if (searchQuery === 'factura') {
        searchQuery = 'F'
      } else if (searchQuery.includes('credito') || searchQuery === 'nota de credito') {
        searchQuery = 'C'
      }

      filtered = data?.details.filter(
        (detail) =>
          detail.child_document_reference.includes(searchQuery) ||
          detail.child_document_type === searchQuery
      )
    }

    setFilteredDetails(filtered)
  }

  const resetFilters = () => {
    setSearchInput('')
    setFilteredDetails(data?.details || [])
  }

  const fetchDataFromServer = async () => {
    setIsFetching(true)
    try {
      const urlSplit = window.location.href.split('/')
      const settlementId = urlSplit[urlSplit.length - 1]
      const response = await fetchWithAuth(`${BACKEND_URL}/settlements/${settlementId}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })

      if (response === undefined) {
        setData(null)
        setError('No se encontró la liquidación.')
        return
      }
      setData(response)
      if (response.cash_count.length > 0) {
        setCashData(response.cash_count[0].denominations)
        setCashDataTotales(response.cash_count[0])
      }
    } catch (error) {
      console.error('Error fetching data:', error)
      setData(null)
      setError('No se encontró la liquidación')
    } finally {
      setIsFetching(false)
    }
  }

  const getListTabStyles = (tab) => {
    return selectedTab === tab ? 'active' : 'nav-item'
  }

  const getAnchorTabStyles = (tab) => {
    return selectedTab === tab ? 'active' : 'inactive-tab'
  }

  const handleChargeClick = (uid) => {
    window.open(`/cobros/${uid}`, '_blank')
  }

  useEffect(() => {
    fetchDataFromServer()
  }, [])

  useEffect(() => {
    setFilteredDetails(data?.details || [])
  }, [data])

  useEffect(() => {
    if (allSelectedFilterOptions != null) {
      if (allSelectedFilterOptions.length == 1) {
        var contado = allSelectedFilterOptions[0] == 'Contado'
        setFilteredDetails(
          data?.details.filter((detail) =>
            contado ? detail.charge_uid != null : detail.charge_uid == null
          )
        )
      } else {
        setFilteredDetails(data?.details || [])
      }
    }
  }, [allSelectedFilterOptions])

  const handleToggleCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed)
    localStorage.setItem('collapsedState', newCollapsed)
  }

  useEffect(() => {
    const collapsedFromStorage = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedFromStorage)
  }, [])

  function formatDate(dateyears) {
    const date = new Date(dateyears)
    return date.toLocaleDateString('es', { day: '2-digit', month: '2-digit', year: 'numeric' })
  }

  function formatTime(time) {
    const date = new Date(time)
    return date.toLocaleTimeString('es', { hour: '2-digit', minute: '2-digit', hour12: true })
  }

  const handleSelectionChange = (identifier, selectedOptions) => {
    const selectedOptionsArray = Array.from(selectedOptions)
    setAllSelectedFilterOptions(selectedOptionsArray)
  }

  const buildCsvFile = () => {
    let rows = []
    rows.push(['Reporte:', 'Detalles de Liquidación'])
    rows.push(['Número de liquidación:', data.uid || 'N/A'])
    rows.push(['Código de usuario:', data.user_uid || 'N/A'])
    rows.push(['Usuario:', data.user_full_name || 'N/A'])
    rows.push(['Fecha de creación:', getTimestampFromDateTime(data.collected_at) || 'N/A'])
    rows.push([''])
    rows.push(['RESUMEN TOTAL'])
    rows.push([''])
    rows.push(['Efectivo:', formatMoney(data.cash_amount || 0)])
    rows.push(['Transferencia:', formatMoney(data.transference_amount || 0)])
    rows.push(['Pago digital:', formatMoney(data.payment_digital_amount || 0)])
    rows.push(['Depósito bancario:', formatMoney(data.deposit_amount || 0)])
    rows.push(['Cheque:', formatMoney(data.cheque_amount || 0)])
    rows.push(['Tarjeta de crédito:', formatMoney(data.credit_card_amount || 0)])
    rows.push(['Vale digital:', formatMoney(data.vale_digital_amount || 0)])
    rows.push(['Pago policía:', formatMoney(data.payment_amount || 0)])
    rows.push(['TOTAL COBRADO:', formatMoney(data.total_amount || 0)])
    rows.push([''])

    if (!data.charge_uid || data.charge_uid.length === 0) {
      rows.push(['Total entregas a crédito:', formatMoney(data.delivery_credit_amount || 0)])
      rows.push([''])
    }
    const headersReceived = [
      'FECHA',
      'HORA',
      'CÓDIGO',
      'CLIENTE',
      'NUMERO DE DOCUMENTO',
      'TIPO DE DOCUMENTO',
      'NUMERO DE COBRO',
      'TIPO DE PAGO',
      'TOTAL'
    ]
    const headersCancelled = [
      'FECHA',
      'HORA',
      'CÓDIGO',
      'CLIENTE',
      'NUMERO DE DOCUMENTO',
      'TIPO DE DOCUMENTO',
      'ESTATUS',
      'TOTAL'
    ]

    let cancelledDetails = [
      ['DETALLES DE DOCUMENTOS ANULADOS ASOCIADOS A ESTA LIQUIDACIÓN:'],
      [''],
      headersCancelled
    ]
    let receivedDetails = [
      ['DETALLES DE DOCUMENTOS ASOCIADOS A ESTA LIQUIDACIÓN:'],
      [''],
      headersReceived
    ]

    data.details.forEach((detail) => {
      let payment_Type = 'N/A'
      if (detail.client_payment_type) {
        payment_Type =
          detail.client_payment_type.toLowerCase() === 'credito' ? 'Crédito' : 'Contado'
      } else if (!detail.charge_uid) {
        payment_Type = 'Crédito'
      }

      let detailArrayReceived = [
        formatDate(detail.date_of_charge) || 'N/A',
        formatTime(detail.date_of_charge) || 'N/A',
        detail.client_uid || 'N/A',
        detail.client_name || 'N/A',
        detail.child_document_reference || 'N/A',
        parseDocumentType(detail.child_document_type) || 'N/A',
        detail.charge_uid ? detail.charge_uid : 'N/A',
        payment_Type,
        formatMoney(detail.child_document_total || 0)
      ]

      let detailArrayCancelled = [
        formatDate(detail.date_of_charge) || 'N/A',
        formatTime(detail.date_of_charge) || 'N/A',
        detail.client_uid || 'N/A',
        detail.client_name || 'N/A',
        detail.child_document_reference || 'N/A',
        parseDocumentType(detail.child_document_type) || 'N/A',
        'Anulado',
        formatMoney(detail.child_document_total || 0)
      ]

      if (detail.charge_status === 'cancelled') {
        cancelledDetails.push(detailArrayCancelled)
      } else if (detail.charge_status === 'received' || payment_Type === 'Crédito') {
        receivedDetails.push(detailArrayReceived)
      }
    })

    rows = rows.concat(receivedDetails)
    rows.push([''])
    rows = rows.concat(cancelledDetails)

    return rows
  }

  return (
    <div>
      {isFetching === false && data ? (
        <>
          <div>
            <div>
              <div>
                <div>
                  <TopBar
                    searchPlaceholder={''}
                    onToggleCollapse={handleToggleCollapse}
                    collapsed={collapsed}
                  />
                </div>
                <div>
                  {error ? (
                    <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
                      <div className="main-show-header">
                        <p>{error}</p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
                        <div className="main-show-header">
                          <h2>Liquidación: {data?.uid}</h2>
                        </div>
                        <div className="index-table">
                          <div className="show-area">
                            <div>
                              <div>
                                <div id="tabs">
                                  <div className="">
                                    <ul className="nav nav-tabs">
                                      {tabs.map((value, index) => (
                                        <li
                                          key={index}
                                          onClick={() => setSelectedTab(value)}
                                          id="details_tab"
                                          className={`nav-link ${getListTabStyles(value)} cursor-pointer`}
                                        >
                                          <a
                                            className={getAnchorTabStyles(value)}
                                            style={{ textDecoration: 'none' }}
                                          >
                                            {value}
                                          </a>
                                        </li>
                                      ))}
                                      <div className="range-dates">
                                        <CSVLink
                                          className="btn btn-default download-report-button-settlements"
                                          data={buildCsvFile()}
                                          filename={`DetallesDeLiquidacion_${data?.user_full_name}_${
                                            data?.business_uid
                                          }_${getCurrentDate()}.csv`}
                                        >
                                          <FontAwesomeIcon icon={faArrowCircleDown} />
                                          Descargar Liquidación
                                        </CSVLink>
                                      </div>
                                    </ul>
                                  </div>
                                </div>
                                <div>
                                  <div className="container-fluid">
                                    <div className="">
                                      <div className=" show-product-header">
                                        <div className="col-sm-6">
                                          {selectedTab === 'Detalles' && (
                                            <h3 className="margin-text-subtitle">
                                              Detalles de liquidación:
                                            </h3>
                                          )}
                                          {selectedTab === 'Documentos' && (
                                            <h3 className="margin-text-subtitle">
                                              Detalles de documentos asociados a esta liquidación:{' '}
                                            </h3>
                                          )}
                                          {selectedTab === 'Anulados' && (
                                            <h3 className="margin-text-subtitle">
                                              Detalles de documentos anulados asociados a esta
                                              liquidación:{' '}
                                            </h3>
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        <hr />
                                        <div>
                                          <table cellSpacing="10">
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <div className="mr-85 mb-10">
                                                    <b>Fecha de creación:</b>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="mb-10">
                                                    {getTimestampFromDateTime(data?.collected_at)}
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  <div className="mr-85 mb-10">
                                                    <b>Código de usuario:</b>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="mb-10">{data?.user_uid}</div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  <div className="mr-85 mb-10">
                                                    <b>Usuario:</b>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="mb-10">
                                                    {data?.user_full_name}
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>

                                          <hr />
                                          {selectedTab === 'Detalles' && (
                                            <div className="totals-row">
                                              <div className="totals-column">
                                                <div
                                                  className={`totals-container-left${data.cash_count.length > 0 ? '-column' : ''}`}
                                                >
                                                  <div className="totals-card-left">
                                                    <div className="totals-line">
                                                      <div>
                                                        <b>RESUMEN TOTAL</b>
                                                      </div>
                                                      <div></div>
                                                    </div>
                                                    {data?.is_cash_enabled && (
                                                      <div className="totals-line">
                                                        <div>Efectivo:</div>
                                                        <div>{formatMoney(data?.cash_amount)}</div>
                                                      </div>
                                                    )}
                                                    {data?.is_transfer_enabled && (
                                                      <div className="totals-line">
                                                        <div>Transferencia:</div>
                                                        <div>
                                                          {formatMoney(data.transference_amount)}
                                                        </div>
                                                      </div>
                                                    )}
                                                    {data?.is_digital_enabled && (
                                                      <div className="totals-line">
                                                        <div>Pago Digital:</div>
                                                        <div>
                                                          {formatMoney(data.payment_digital_amount)}
                                                        </div>
                                                      </div>
                                                    )}

                                                    {data.is_deposit_enabled && (
                                                      <div className="totals-line">
                                                        <div>Depósito bancario:</div>
                                                        <div>
                                                          {formatMoney(data.deposit_amount)}
                                                        </div>
                                                      </div>
                                                    )}
                                                    {data.is_cheque_enabled && (
                                                      <div className="totals-line">
                                                        <div>Cheque:</div>
                                                        <div>{formatMoney(data.cheque_amount)}</div>
                                                      </div>
                                                    )}
                                                    {data.is_credit_card_enabled && (
                                                      <div className="totals-line">
                                                        <div>Tarjeta de crédito:</div>
                                                        <div>
                                                          {formatMoney(data.credit_card_amount)}
                                                        </div>
                                                      </div>
                                                    )}

                                                    {data.is_vale_enabled && (
                                                      <div className="totals-line">
                                                        <div>Vale digital:</div>
                                                        <div>
                                                          {formatMoney(data.vale_digital_amount)}
                                                        </div>
                                                      </div>
                                                    )}
                                                    {data.is_push_money_enabled && (
                                                      <div className="totals-line">
                                                        <div>Viñetas:</div>
                                                        <div>
                                                          {formatMoney(data.push_money_amount)}
                                                        </div>
                                                      </div>
                                                    )}
                                                    {data.is_police_enabled && (
                                                      <div
                                                        className="totals-line"
                                                        style={{ color: '#F15D5D' }}
                                                      >
                                                        <div>Pago policía:</div>
                                                        <div>
                                                          -{formatMoney(data.payment_amount)}
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                                <div
                                                  className={`totals-container-left${data.cash_count.length > 0 ? '-column' : ''}`}
                                                >
                                                  <div className="totals-card-left">
                                                    <div className="totals-line">
                                                      <div>
                                                        <h4>
                                                          <b className="totals-line-bold-text">
                                                            TOTAL COBRADO:{' '}
                                                          </b>
                                                        </h4>
                                                      </div>
                                                      <div>
                                                        <h4>
                                                          <b className="totals-line-bold-text">
                                                            {formatMoney(data.total_amount)}
                                                          </b>
                                                        </h4>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                {data.delivery_credit_amount &&
                                                  data.delivery_credit_amount > 0 && (
                                                    <div
                                                      className={`totals-container-left${data.cash_count.length > 0 ? '-column' : ''}`}
                                                    >
                                                      <div className="totals-card-left">
                                                        <div className="totals-line">
                                                          <div>
                                                            <b className="totals-line-bold-text">
                                                              Total entregas a crédito:{' '}
                                                            </b>
                                                          </div>
                                                          <div>
                                                            {formatMoney(
                                                              data.delivery_credit_amount
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                              </div>
                                              {data.cash_count.length > 0 && (
                                                <CashCount
                                                  cashData={cashData}
                                                  cashDataTotales={cashDataTotales}
                                                />
                                              )}
                                            </div>
                                          )}

                                          {selectedTab === 'Documentos' && (
                                            <div>
                                              <div>
                                                <h4 className="text-sub-title">
                                                  Filtrar liquidaciones por:
                                                </h4>
                                                <div className="filters-search-container mb-10">
                                                  <FontAwesomeIcon
                                                    className="filter-icon mr-10"
                                                    icon={faFilter}
                                                  />
                                                  <div className="filters-container">
                                                    <div key={`flr-tipo-de-pago`} className="mr-10">
                                                      <ReactCheckboxFilter
                                                        className="mr-10"
                                                        title={'TIPO DE PAGO'}
                                                        identifier={'TIPO DE PAGO'}
                                                        options={['Contado', 'Crédito']}
                                                        onSelectionChange={handleSelectionChange}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="search-input-containter ">
                                                    <input
                                                      value={searchInput}
                                                      onChange={handleSearch}
                                                      onKeyDown={handleEnterSearch}
                                                      className="round-search-input ft-12"
                                                      placeholder="Buscar por número de documento o de cobro"
                                                    />
                                                    {searchInput === '' && (
                                                      <FontAwesomeIcon
                                                        icon={faMagnifyingGlass}
                                                        className="search-icon"
                                                      />
                                                    )}
                                                    {searchInput && (
                                                      <div
                                                        title="Limpiar texto"
                                                        onClick={resetFilters}
                                                        className="round-search-input-x"
                                                      >
                                                        X
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>

                                              <Table hover responsive>
                                                <thead className="table-header-gray">
                                                  <tr>
                                                    <th
                                                      style={{
                                                        verticalAlign: 'middle',
                                                        padding: '10px 25px'
                                                      }}
                                                    >
                                                      <span className="th-header-text">
                                                        FECHA Y HORA
                                                      </span>
                                                    </th>
                                                    <th style={{ verticalAlign: 'middle' }}>
                                                      <span className="th-header-text">CÓDIGO</span>
                                                    </th>
                                                    <th style={{ verticalAlign: 'middle' }}>
                                                      <span className="th-header-text">
                                                        CLIENTE
                                                      </span>
                                                    </th>
                                                    <th style={{ verticalAlign: 'middle' }}>
                                                      <span className="th-header-text">
                                                        NÚMERO DE DOCUMENTO
                                                      </span>
                                                    </th>
                                                    <th style={{ verticalAlign: 'middle' }}>
                                                      <span className="th-header-text">
                                                        TIPO DE DOCUMENTO
                                                      </span>
                                                    </th>
                                                    <th style={{ verticalAlign: 'middle' }}>
                                                      <span className="th-header-text">
                                                        NÚMERO DE COBRO
                                                      </span>
                                                    </th>
                                                    <th style={{ verticalAlign: 'middle' }}>
                                                      <span className="th-header-text">
                                                        TIPO DE PAGO
                                                      </span>
                                                    </th>
                                                    <th style={{ verticalAlign: 'middle' }}>
                                                      <span className="th-header-text">TOTAL</span>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {filteredDetails
                                                    .reverse()
                                                    .filter(
                                                      (charge) =>
                                                        charge.charge_status !== 'cancelled'
                                                    ).length > 0 ? (
                                                    filteredDetails
                                                      .reverse()
                                                      .filter(function (charge) {
                                                        return charge.charge_status !== 'cancelled'
                                                      })
                                                      .map((model, index) => (
                                                        <tr key={index}>
                                                          <td className="td-text-limited td-text-limited-space">
                                                            {getTimestampFromDateTime(
                                                              model?.date_of_charge
                                                            )}
                                                          </td>
                                                          <td className="td-text-limited">
                                                            {model?.client_uid}
                                                          </td>

                                                          <td
                                                            className="td-text-limited"
                                                            style={{ maxWidth: '350px' }}
                                                          >
                                                            <div className="text-limit-2-lines ">
                                                              {model?.client_name}
                                                            </div>
                                                          </td>
                                                          <td className="td-text-limited">
                                                            {model?.child_document_reference}
                                                          </td>
                                                          <td className="td-text-limited">
                                                            {parseDocumentType(
                                                              model?.child_document_type
                                                            )}
                                                          </td>
                                                          <td className="td-text-limited">
                                                            {model?.charge_uid ? (
                                                              <span
                                                                onClick={() =>
                                                                  handleChargeClick(
                                                                    model?.charge_uid
                                                                  )
                                                                }
                                                                className="ubiqua-link"
                                                              >
                                                                {model?.charge_uid}
                                                              </span>
                                                            ) : (
                                                              <>-</>
                                                            )}
                                                          </td>
                                                          <td className="td-text-limited">
                                                            {model?.charge_uid ? (
                                                              <>Contado</>
                                                            ) : (
                                                              <>Crédito</>
                                                            )}
                                                          </td>
                                                          <td className="td-text-limited">
                                                            {formatMoney(
                                                              Number(model?.child_document_total)
                                                            )}
                                                          </td>
                                                        </tr>
                                                      ))
                                                  ) : (
                                                    <tr>
                                                      <td
                                                        colSpan="7"
                                                        className="td-text-limited td-text-limited-space"
                                                      >
                                                        No hay resultados
                                                      </td>
                                                    </tr>
                                                  )}
                                                </tbody>
                                              </Table>
                                            </div>
                                          )}

                                          {selectedTab === 'Anulados' && (
                                            <div>
                                              <div className="title-and-search-containter">
                                                <h4 className="text-sub-title">
                                                  Detalles de liquidaciones anuladas:
                                                </h4>
                                                <div className="search-input-containter mb-20">
                                                  <input
                                                    value={searchInput}
                                                    onChange={handleSearch}
                                                    onKeyDown={handleEnterSearch}
                                                    className="round-search-input"
                                                    style={{ fontSize: 12 }}
                                                    placeholder="Buscar por número de documento o tipo"
                                                  />
                                                  {searchInput === '' && (
                                                    <FontAwesomeIcon
                                                      icon={faMagnifyingGlass}
                                                      className="search-icon"
                                                    />
                                                  )}
                                                  {searchInput && (
                                                    <div
                                                      title="Limpiar texto"
                                                      onClick={resetFilters}
                                                      className="round-search-input-x"
                                                    >
                                                      X
                                                    </div>
                                                  )}
                                                </div>
                                              </div>

                                              <Table hover responsive>
                                                <thead className="table-header-gray">
                                                  <tr>
                                                    <th
                                                      style={{
                                                        verticalAlign: 'middle',
                                                        padding: '10px 25px'
                                                      }}
                                                    >
                                                      <span className="th-header-text ">
                                                        FECHA Y HORA
                                                      </span>
                                                    </th>
                                                    <th style={{ verticalAlign: 'middle' }}>
                                                      <span className="th-header-text">CÓDIGO</span>
                                                    </th>
                                                    <th style={{ verticalAlign: 'middle' }}>
                                                      <span className="th-header-text">
                                                        CLIENTE
                                                      </span>
                                                    </th>
                                                    <th style={{ verticalAlign: 'middle' }}>
                                                      <span className="th-header-text">
                                                        NÚMERO DE DOCUMENTO
                                                      </span>
                                                    </th>
                                                    <th style={{ verticalAlign: 'middle' }}>
                                                      <span className="th-header-text">
                                                        TIPO DE DOCUMENTO
                                                      </span>
                                                    </th>
                                                    <th style={{ verticalAlign: 'middle' }}>
                                                      <span className="th-header-text">
                                                        ESTATUS
                                                      </span>
                                                    </th>
                                                    <th style={{ verticalAlign: 'middle' }}>
                                                      <span className="th-header-text">TOTAL</span>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {filteredDetails
                                                    .reverse()
                                                    .filter(
                                                      (charge) =>
                                                        charge.charge_status === 'cancelled'
                                                    ).length > 0 ? (
                                                    filteredDetails
                                                      .reverse()
                                                      .filter(function (charge) {
                                                        return charge.charge_status === 'cancelled'
                                                      })
                                                      .map((model, index) => (
                                                        <tr key={index}>
                                                          <td className="td-text-limited">
                                                            {getTimestampFromDateTime(
                                                              model?.date_of_charge
                                                            )}
                                                          </td>
                                                          <td className="td-text-limited">
                                                            {model?.client_uid}
                                                          </td>
                                                          <td className="td-text-limited">
                                                            {model?.client_name}
                                                          </td>
                                                          <td className="td-text-limited">
                                                            {model?.child_document_reference}
                                                          </td>
                                                          <td className="td-text-limited">
                                                            {parseDocumentType(
                                                              model?.child_document_type
                                                            )}
                                                          </td>
                                                          <td className="td-text-limited">
                                                            Anulado
                                                          </td>
                                                          <td className="td-text-limited">
                                                            {formatMoney(
                                                              Number(model?.child_document_total)
                                                            )}
                                                          </td>
                                                        </tr>
                                                      ))
                                                  ) : (
                                                    <tr>
                                                      <td
                                                        colSpan="7"
                                                        className="td-text-limited td-text-limited-space"
                                                      >
                                                        No hay resultados
                                                      </td>
                                                    </tr>
                                                  )}
                                                </tbody>
                                              </Table>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <NavBar activeItem="Cobro" isEditing={false} collapsed={collapsed} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <TopBar
            searchPlaceholder={''}
            onToggleCollapse={handleToggleCollapse}
            collapsed={collapsed}
          />
          <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
            <div className="show-title">
              <p>Obteniendo datos ....</p>
            </div>
          </div>
          <div>
            <NavBar activeItem="Liquidaciones" isEditing={false} collapsed={collapsed} />
          </div>
        </>
      )}
    </div>
  )
}
export default SettlementsDetails
