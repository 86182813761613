import React from 'react'
import cookie from 'react-cookies'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import PointOfSaleView from './PointOfSaleView'
import fetchWithAuth from '../helpers/fetchWithAuth'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BACKEND_URL = USE_BUSINESS_URL
  ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : process.env.REACT_APP_BACKEND_URL
export default class PointOfSaleApp extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      userName: '',
      per_page: 25,
      current_page: 1,
      total_pages: 1,
      isFetching: true,
      data: null,
      searchPlaceholder: 'Buscar tareas en punto de venta por código o descripción',
      title: 'Ejecución en punto de venta',
      query: '',
      collapsed: false
    }
  }

  componentDidMount() {
    this.setPaginationParamsState()
    this.setQueryState()

    const collapsed = localStorage.getItem('collapsedState') === 'true'
    this.setState({ collapsed })
  }

  setQueryState = () => {
    if (window.location.href.indexOf('query') >= 0) {
      const query = decodeURI(
        window.location.href.split('query=')[window.location.href.split('query=').length - 1]
      )
      this.setState({ query: query }, this.fetchDataFromServer)
    } else {
      this.setState({ query: '' }, this.fetchDataFromServer)
    }
  }

  setPaginationParamsState = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const page = parseInt(searchParams.get('page')) || 1

    if (page !== this.state.current_page) {
      this.setState({ current_page: page }, this.fetchDataFromServer)
    }
  }

  fetchDataFromServer = async () => {
    this.setState({ isFetching: true })

    const { per_page, current_page, query } = this.state
    const title = query ? `Resultados de la búsqueda: ${query}` : 'Tareas en punto de venta'

    try {
      const response = await fetchWithAuth(
        `${BACKEND_URL}/pos_tasks?page=${current_page}&per_page=${per_page}${query ? `&q=${query}` : ''}&business_tenant_uid=${cookie.load('business_tenant_uid')}`,
        { method: 'GET' }
      )

      if (!response) {
        throw new Error('No response from server')
      }

      this.setState({
        data: response.result,
        isFetching: false,
        total_pages: Math.ceil(response.count / per_page),
        title: title
      })
    } catch (error) {
      console.error('Error fetching data:', error)
      this.setState({
        data: [],
        isFetching: false,
        total_pages: 0,
        title: title
      })
    }
  }
  handlePaginationClick = (event) => {
    // <a> = when pagination elements were clicked
    if (event.target.tagName === 'A') {
      this.handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      this.handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      this.handlePagination(event.target.parentNode.parentNode)
    }
  }

  handlePagination = (target) => {
    let newPage = parseInt(this.state.current_page)

    switch (target.id) {
      case 'first-page':
        newPage = 1
        break
      case 'last-page':
        newPage = this.state.total_pages
        break
      case 'previous-page':
        if (this.state.current_page > 1) {
          newPage = this.state.current_page - 1
        }
        break
      case 'next-page':
        if (this.state.current_page < this.state.total_pages) {
          newPage = this.state.current_page + 1
        }
        break
      default:
        return
    }

    if (newPage !== this.state.current_page) {
      this.setState({ current_page: newPage }, () => {
        this.updateURL()
        this.fetchDataFromServer()
      })
    }
  }

  updateURL = () => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('page', this.state.current_page)
    if (this.state.query) {
      searchParams.set('query', this.state.query)
    } else {
      searchParams.delete('query')
    }
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`
    window.history.replaceState(null, '', newUrl)
  }

  handleSearch = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      const query = event.target.value.trim()

      this.setState({ query, current_page: 1 }, () => {
        this.updateURL()
        this.fetchDataFromServer()
      })
    }
  }
  handleToggleCollapse = (collapsed) => {
    this.setState({ collapsed }, () => {
      localStorage.setItem('collapsedState', collapsed)
    })
  }

  handleSearchUpdate = (newQuery) => {
    this.setState({ query: newQuery }, this.fetchDataFromServer)
  }

  render() {
    return (
      <div>
        <div>
          <div onKeyDown={this.handleSearch}>
            <TopBar
              searchPlaceholder={this.state.searchPlaceholder}
              userName={this.state.userName}
              onToggleCollapse={this.handleToggleCollapse}
              collapsed={this.state.collapsed}
            />
          </div>
          <div onClick={this.handlePaginationClick}>
            <PointOfSaleView
              data={this.state.data}
              isFetching={this.state.isFetching}
              title={this.state.title}
              total_pages={this.state.total_pages}
              onSearchUpdate={this.handleSearchUpdate}
              current_page={this.state.current_page}
              collapsed={this.state.collapsed}
            />
          </div>
        </div>
        <div>
          <NavBar
            activeItem="Ejecución punto de venta"
            isEditing={false}
            collapsed={this.state.collapsed}
          />
        </div>
      </div>
    )
  }
}
