import React, { useContext, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import cookie from 'react-cookies'
import { MetroSpinner } from 'react-spinners-kit'
import ReactFilter from '../components/inputs/ReactFilter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDown,
  faPlus,
  faArrowLeft,
  faArrowCircleDown
} from '@fortawesome/free-solid-svg-icons'
import Pagination from '../layout/Pagination'
import { CSVLink } from 'react-csv'
import { getCurrentDate } from '../helpers/formatDate'
import { validateAccessRole } from '../helpers/userRole'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BASE_BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const BACKEND_URL = USE_BUSINESS_URL
  ? `${BASE_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : BASE_BACKEND_URL


export default function EssentialsAssociationsView(props) {
  const initPaginationData = () => {
    const searchParams = new URLSearchParams(window.location.search)
    let page = searchParams.get('page')
    let currentPage = 1
    if (page) {
      currentPage = Number(page)
    } else {
      searchParams.set('page', '1')
      let newRelativePathQuery = `${window.location.pathname}?${searchParams}`
      window.history.pushState(null, '', newRelativePathQuery)
    }
    return {
      per_page: 50,
      count: 0,
      current_page: currentPage,
      total_pages: 1,
      query: ''
    }
  }

  const [editionMode, setEditionMode] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [searchQuery, setSearchQuery] = useState(null)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [savedSelectedProducts, setSavedSelectedProducts] = useState([])
  const [isFetching, setFetching] = useState(false)
  const [isPreparingDownload, setIsPreparingDownload] = useState(false)
  const [isSaving, setSaving] = useState(false)
  const [pageAttribute, setPageAttribute] = useState({
    label: null,
    value: null,
    qualifier: null,
    segment: null
  }) // {label: 'Lista de precio', value: 'price_list_name', qualifier: 'qualifier_2', segment: 'SBPN-1'}
  const [pagination, setPagination] = useState(initPaginationData())
  const [products, setProducts] = useState([])
  const [productsToDownload, setProductsToDownload] = useState([])
  const [qualifiers, setQualifiers] = useState()
  const [areAllProductsSelected, setAreAllProductsSelected] = useState(false)
  const [markAllSelected, setMarkAllSelected] = useState(false)
  const [collapsed, setCollapsed] = useState(props.collapsed)

  const saveEssentialProductsQualifiers = async () => {
    setSaving(true)
    const response = await fetch(
      `${BACKEND_URL}/qualifiers?business_tenant_uid=${cookie.load('business_tenant_uid')}&save_essentials_products=true`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          business_tenant_uid: cookie.load('business_tenant_uid'),
          select_all: markAllSelected || undefined,
          essential_products: markAllSelected ? [] : [...selectedProducts],
          essential_segment: pageAttribute.segment,
          essential_attribute: pageAttribute.value
        })
      }
    )
    if (response.status === 401) {
      return console.error(response)
    }
    const data = await response.json()
    if (data?.essential_products_hash) {
      await fetchQualifiersFromServer()
    }
    setSaving(false)
  }

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
  }

  const handleEnterSearch = (e) => {
    if (e.key === 'Enter') {
      buildSearchQuery()
    }
  }

  const buildSearchQuery = () => {
    let queryParam = ''
    if (searchInput) {
      queryParam = `&q=${searchInput}`
    }
    setSearchQuery(queryParam)
  }

  const handlePagination = (target) => {
    let targetId = target.id
    switch (targetId) {
      case 'first-page':
        if (pagination.current_page !== 1) {
          setPagination({ ...pagination, current_page: 1 })
        }
        break
      case 'last-page':
        if (pagination.current_page !== pagination.total_pages) {
          setPagination({ ...pagination, current_page: pagination.total_pages })
        }
        break
      case 'previous-page':
        if (pagination.current_page !== 1) {
          setPagination({ ...pagination, current_page: pagination.current_page - 1 })
        }
        break
      case 'next-page':
        if (pagination.current_page !== pagination.total_pages) {
          setPagination({ ...pagination, current_page: pagination.current_page + 1 })
        }
        break
    }
  }

  const handlePaginationClick = (event) => {
    // <a> = when pagination elements were clicked
    if (event.target.tagName === 'A') {
      setProducts([])
      setFetching(true)
      handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      setProducts([])
      setFetching(true)
      handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      setProducts([])
      setFetching(true)
      handlePagination(event.target.parentNode.parentNode)
    }
  }

  const navigateToInfaltables = () => {
    window.location = '/infaltables'
  }

  const selectOrDesellectAll = () => {
    if (areAllProductsSelected) {
      setSelectedProducts([])
    } else {
      let productsToSelect = products?.map((e) => e.db_ref)
      let set = new Set([...productsToSelect, ...selectedProducts])
      setSelectedProducts(Array.from(set))
    }
  }

  const desellectAll = () => {
    setSelectedProducts([])
    setMarkAllSelected(false)
  }

  const selectProduct = (dbRef) => {
    if (selectedProducts?.includes(dbRef)) {
      setSelectedProducts(selectedProducts?.filter((product) => product !== dbRef))
    } else {
      setSelectedProducts([...selectedProducts, dbRef])
    }
  }

  const fetchProductsFromServer = async (forDownload = false) => {
    if (forDownload) {
      setIsPreparingDownload(true)
    } else {
      setFetching(true)
    }
    const per_page = forDownload ? pagination.per_page : 50
    const page = pagination.current_page
    let response = await fetch(
      `${BASE_BACKEND_URL}/businesses/${cookie.load('business_id')}/products?page=${page}&per_page=${per_page}${searchQuery ? `&q=${searchQuery}` : ''}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (response.status === 401) {
      // window.location = '/'
    }
    let data = await response.json()
    if (forDownload) {
      setProductsToDownload(data['table'])
      setIsPreparingDownload(false)
    } else {
      setProductsToDownload(data['table'])
      setProducts(data['table'])
      setPagination({
        ...pagination,
        total_pages: Math.ceil(data['count'] / pagination.per_page),
        count: data['count']
      })
      setFetching(false)
    }
  }

  const fetchQualifiersFromServer = async () => {
    setFetching(true)

    const response = await fetch(
      `${BACKEND_URL}/qualifiers?business_tenant_uid=${cookie.load('business_tenant_uid')}&include_essentials_products=true`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (response.status === 401) {
      return console.error(response)
    }
    const data = await response.json()
    setQualifiers(data?.result)
    setFetching(false)
  }

  const updateURLPaginationData = () => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('page', pagination.current_page)
    let newRelativePathQuery = `${window.location.pathname}?${searchParams}`
    window.history.pushState(null, '', newRelativePathQuery)
  }

  const validateSelectedProducts = () => {
    let currentPageDbRefs = products.map((e) => e.db_ref)
    let selectedProductsOnCurrentPage = selectedProducts?.filter((e) =>
      currentPageDbRefs?.includes(e)
    )
    if (
      selectedProductsOnCurrentPage?.length === currentPageDbRefs?.length &&
      currentPageDbRefs?.length !== 0
    ) {
      setAreAllProductsSelected(true)
    } else {
      setAreAllProductsSelected(false)
    }
  }

  const setUpCurrentAttributeData = () => {
    const searchParams = new URLSearchParams(window.location.search)
    let qualifier = searchParams.get('attribute')
    let segment = searchParams.get('segment')
    if (qualifier && segment) {
      let value = qualifiers?.saved_qualifiers[qualifier]
      setPageAttribute({
        value,
        segment,
        label: qualifiers?.available_qualifiers?.find((item) => item.value === value).label,
        qualifier
      })
    }
  }

  const buildCsvFile = () => {
    const searchParams = new URLSearchParams(window.location.search)
    let qualifierkey = searchParams.get('attribute')
    let segment = searchParams.get('segment')
    let value = qualifiers?.saved_qualifiers[qualifierkey]
    let attribute = qualifiers?.available_qualifiers?.find((item) => item.value === value)?.label

    let rows = []
    for (let i = 0; i < productsToDownload.length; i++) {
      let p = productsToDownload[i]
      if (savedSelectedProducts.includes(p.db_ref)) {
        rows.push([p.db_ref, p.name, p.brand?.name])
      }
    }

    rows.splice(0, 0, ['Código', 'Nombre', 'Marca'])
    rows.splice(0, 0, [])
    rows.splice(0, 0, [`Atributo - ${attribute}: ${segment}`])
    return rows
  }

  useEffect(() => {
    validateSelectedProducts()
  }, [selectedProducts, products])

  useEffect(() => {
    setUpCurrentAttributeData()
  }, [qualifiers])

  useEffect(() => {
    if (pageAttribute.value) {
      let index = `${pageAttribute?.value}-${pageAttribute?.segment}`
      let essentialJson = qualifiers?.saved_qualifiers?.essential_products_hash
      if (essentialJson && essentialJson[index]) {
        setSavedSelectedProducts(essentialJson[index])
      } else {
        setSavedSelectedProducts([])
      }
    }
  }, [pageAttribute])

  useEffect(() => {
    updateURLPaginationData()
    setFetching(true)
    async function fetchAll() {
      await fetchProductsFromServer()
    }
    fetchAll()
  }, [pagination.current_page, searchQuery])

  useEffect(() => {
    if (editionMode) {
      setSelectedProducts(savedSelectedProducts)
    }
  }, [editionMode])

  useEffect(() => {
    initPaginationData()
    async function fetchAll() {
      await fetchProductsFromServer()
      await fetchQualifiersFromServer()
    }
    fetchAll()
  }, [])

  useEffect(() => {
    setCollapsed(props.collapsed)
  }, [props.collapsed])

  return (
    <>
      <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
        <div className="index-header">
          <h2 className="mb-20 float-unset">
            Infaltables -{' '}
            {pageAttribute.label ? `${pageAttribute.label} : ${pageAttribute.segment}` : ''}
          </h2>
        </div>
        <div className="index-table">
          <div className="show-area">
            <div className="header-infaltables-container">
              <h4 className=""> Productos asociados a este segmento:</h4>
              <div className="essential-buttons-associate-container">
                <a className="cursor-pointer mb-10" onClick={navigateToInfaltables}>
                  <FontAwesomeIcon icon={faArrowLeft} /> Regresar a infaltables
                </a>
                {editionMode == false && (
                  <>
                    {validateAccessRole(cookie.load('user_role')?.essentials_module)?.can_write && (
                      <button
                        className="add-button-products outline mb-10 ml-20"
                        onClick={() => {
                          setEditionMode(!editionMode)
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} /> Agregar productos
                      </button>
                    )}
                    <CSVLink
                      className="btn btn-default download-report-button-list-product mb-10 ml-10 extend-old-dw-button"
                      data={buildCsvFile()}
                      asyncOnClick={true}
                      onClick={async (event, done) => {
                        await fetchProductsFromServer(true)
                        done(true)
                      }}
                      disabled={isPreparingDownload}
                      filename={`ListadoProductosInfaltable_${cookie.load(
                        'business_name'
                      )}_${getCurrentDate()}.csv`}
                    >
                      <FontAwesomeIcon icon={faArrowCircleDown} />
                      {isPreparingDownload && 'Descargando...'}
                      {!isPreparingDownload && 'Descargar productos'}
                    </CSVLink>
                  </>
                )}

                {editionMode == true && (
                  <>
                    {!isSaving && (
                      <button
                        className="cancel-button mb-10 ml-20"
                        onClick={() => {
                          desellectAll()
                          setEditionMode(!editionMode)
                        }}
                      >
                        Cancelar
                      </button>
                    )}
                    {isSaving && (
                      <button className="save-button mb-10 ml-20" disabled onClick={() => {}}>
                        Guardando...
                      </button>
                    )}

                    {!isSaving && (
                      <input
                        className="save-button mb-10"
                        value="Guardar"
                        type="button"
                        onClick={async () => {
                          await saveEssentialProductsQualifiers()
                          setEditionMode(!editionMode)
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            <hr />

            <div className="title-and-search-containter">
              <h4 className="">Listado de productos:</h4>

              <div className="search-input-containter search-input-product-list mb-10">
                <input
                  value={searchInput}
                  onChange={handleSearch}
                  onKeyDown={handleEnterSearch}
                  className="round-search-input"
                  placeholder="Buscar producto por código o nombre"
                />
                {searchInput === '' && (
                  <span className="glyphicon glyphicon-search form-control-feedback" />
                )}
                {searchInput && (
                  <div
                    title="Limpiar texto"
                    onClick={() => {
                      setSearchInput('')
                      setSearchQuery('')
                    }}
                    className="round-search-input-x"
                  >
                    X
                  </div>
                )}
              </div>
            </div>

            {/* {areAllProductsSelected && ( */}
            <div className="mb-10">
              {!markAllSelected && editionMode && selectedProducts?.length > 0 && (
                <>
                  Usted ha seleccionado {selectedProducts?.length} productos de {pagination.count}.{' '}
                  <strong>
                    <a className="ubiqua-link" onClick={desellectAll}>
                      Anular selección
                    </a>
                  </strong>
                </>
              )}
              {markAllSelected && editionMode && (
                <>
                  Todos los {pagination.count} productos están seleccionados.{' '}
                  <strong>
                    <a className="ubiqua-link" onClick={desellectAll}>
                      Anular selección
                    </a>
                  </strong>
                </>
              )}
            </div>
            {/* )} */}

            <Table hover responsive>
            <thead className="table-header-gray">
            <tr>
                  <th style={{ verticalAlign: 'middle' }}>
                    {editionMode && (
                      <input
                        type="checkbox"
                        checked={areAllProductsSelected}
                        onClick={selectOrDesellectAll}
                      ></input>
                    )}
                    {!editionMode && <span className="th-header-text">ACTIVO</span>}
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">CÓDIGO</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">NOMBRE</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">MARCA</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {products?.map((p, indx) => (
                  <tr key={indx}>
                    <td className="td-text-limited" style={{ width: '0' }}>
                      <input
                        disabled={!editionMode}
                        type="checkbox"
                        checked={
                          (editionMode && selectedProducts?.includes(p.db_ref)) ||
                          (!editionMode && savedSelectedProducts?.includes(p.db_ref)) ||
                          markAllSelected
                        }
                        onClick={() => {
                          selectProduct(p.db_ref)
                        }}
                      ></input>
                    </td>
                    <td className="td-text-limited">{p.db_ref}</td>
                    <td className="td-text-limited">{p.name}</td>
                    <td className="td-text-limited">{p.brand?.name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {isFetching && (
              <div className="td-centered">
                <div className="loading-box ">
                  <MetroSpinner size={30} color="#525050" />
                  <span style={{ marginLeft: '10px', color: '#525050' }}>Actualizando...</span>
                </div>
              </div>
            )}
            {pagination.total_pages > 1 && (
              <div
                onClick={(e) => {
                  handlePaginationClick(e)
                }}
              >
                <Pagination
                  current_page={pagination.current_page}
                  total_pages={pagination.total_pages}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
