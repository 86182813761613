import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies'
import { DispatchConsumer } from '../context/OrderContext'
import ConvertOrderInput from '../component/protected_component/ConvertOrderInput'
import { formatMoney } from '../../helpers/utils'
import { Table } from 'react-bootstrap'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const OrderDetailsTab = ({ orderDetails: initialOrderDetails, transactionUuid }) => {
  const [orderDetails, setOrderDetails] = useState(initialOrderDetails || []);
  const [customFields, setCustomFields] = useState(initialOrderDetails?.custom_fields || []);
  const [taxSettings, setTaxSettings] = useState(cookie.load('tax_settings'));
  const [parsedDetails, setParsedDetails] = useState([]);

  useEffect(() => {
    setOrderDetails(initialOrderDetails);
    setCustomFields(initialOrderDetails?.custom_fields || []);

    const detailsWithParsedFields = initialOrderDetails.details.map((detail) => {
      let parsedFields = [];
  
      if (detail.custom_fields_item) {
        if (Array.isArray(detail.custom_fields_item)) {
          parsedFields = detail.custom_fields_item.map((field) => ({
            ...field,
            value: field.value ?? '-', 
          }));
        } else if (typeof detail.custom_fields_item === "string" && detail.custom_fields_item !== "[]" && detail.custom_fields_item.trim() !== "") {
          try {
            parsedFields = JSON.parse(detail.custom_fields_item).map((field) => ({
              ...field,
              value: field.value ?? '-',
            }));
          } catch (error) {
            console.error("Error al parsear custom_fields_item:", error, detail.custom_fields_item);
          }
        }
      }
  
      return { ...detail, parsedFields };
    });

    setParsedDetails(detailsWithParsedFields);
  }, [initialOrderDetails]);

  const customFieldKeys = Array.from(
    new Set(parsedDetails.flatMap((detail) => detail.parsedFields.map((f) => f.label)))
  );
  
  const getFormattedDate = (createdDate) => {
    const d = new Date(createdDate);
    return d.toLocaleString();
  };

  const cancelOrder = () => {
    const response = window.confirm('¿Esta seguro que quiere anular el pedido?');
    if (response) {
      fetch(
        `${BACKEND_URL}/businesses/${cookie.load('business_id')}/orders/${orderDetails.uuid}`,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookie.load('token')}`,
          },
        }
      )
        .then(() => (window.location = `/pedidos/${transactionUuid}`))
        .catch((e) => console.log(e));
    }
  };

  const calculateTaxRate = () => {
    let total = 0;
    if (orderDetails.origin_of_order === 'UbiquaApp') {
      orderDetails.details.forEach(
        (obj) => (total += (parseFloat(obj.extended_price) * parseFloat(obj.tax_rate)) / 100)
      );
    } else {
      orderDetails.details.forEach(
        (obj) => (total += parseFloat(obj.extended_price) * parseFloat(obj.tax_rate))
      );
    }
    return total;
  };

  const calculateTaxRate2 = () => {
    let total = 0;
    orderDetails.details.forEach(
      (obj) => (total += (parseFloat(obj.extended_price) * parseFloat(obj.tax_rate_2)) / 100)
    );
    return total;
  };

  const calculateTotal = () => {
    if (orderDetails.origin_of_order === 'UbiquaApp') {
      return Number(orderDetails.total);
    }
    let total = 0;
    orderDetails.details.forEach((obj) => {
      const calculatedTaxPrice = obj.calculated_tax_price
        ? obj.calculated_tax_price
        : obj.extended_price;
      total += parseFloat(calculatedTaxPrice);
    });
    return total;
  };

  const fieldsToRender =
    customFields.length > 0
      ? customFields.map(({ code, label, value }) => ({
          label,
          value: value || '-', 
        }))
      : [
          { label: "Fecha de creación", value: getFormattedDate(orderDetails.created_date) },
          { label: "Número de pedido", value: orderDetails.order_db_ref },
          { label: "Vendedor", value: orderDetails.salesman_name },
          { label: "Fecha de entrega", value: orderDetails.delivery_date || '-' },
          { label: "Dirección de entrega", value: orderDetails.delivery_address || 'N/A' },
          { label: "Comentarios generales", value: orderDetails.comments || '-' },
        ];


  return (
    <div className="container-fluid">
      <div>
        <DispatchConsumer>
          {({ handleDispatchState, isDispatch }) => (
            <div>
              <div className=" show-product-header">
                  <h3 className="margin-text-subtitle">Detalles del pedido:</h3>
                  
                {!isDispatch && orderDetails.origin_of_order !== 'UbiquaApp' && (
                  <div className="col-sm-6">
                    {orderDetails.status === 'created' && (
                      <div className="show-buttons">
                        <ConvertOrderInput
                          handleDispatchState={handleDispatchState}
                          cancelOrder={cancelOrder}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <hr />
            </div>
          )}
        </DispatchConsumer>
        <div>
          <div className="show-product-header-ubiqua-app show-header">
            <div>
              <div className="order-client-name">{orderDetails.client_name}</div>
              <div className="order-client-db_ref">Código: {orderDetails.client_db_ref}</div>
            </div>
          </div>
          <hr />
          <div>
          <table cellSpacing="10">
          <tbody>
            {fieldsToRender.map((field, index) => (
              <tr key={index}>
                <td>
                  <div className="mr-85 mb-10">
                    <b>{field.label}:</b>
                  </div>
                </td>
                <td>
                  <div className="mb-10">{field.value}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
            <hr />
            <div className="mr-85 mb-10">
              <div>
                <b>Productos en Pedido:</b>
              </div>
            </div>
            <Table hover responsive>
            <thead className="table-header-gray">
                <tr>
                  <th>CÓDIGO</th>
                  <th>NOMBRE DEL PRODUCTO</th>
                  <th>UNIDAD DE VENTA</th>
                  <th>CANTIDAD</th>
                  <th>PRECIO</th>
                  <th>% DESCUENTO</th>
                  <th>SUB-TOTAL</th>
                  {customFieldKeys.map((field) => (
                    <th key={field} className="column-custom">{field}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {parsedDetails.map((model, index) => (
                  <tr key={index}>
                    <td>{model.product_db_ref}</td>
                    <td>{model.product_name}</td>
                    <td>{model.price_unit}</td>
                    <td>{Number(model.quantity).toFixed(2)}</td>
                    <td>{formatMoney(Number(model.unit_price))}</td>
                    <td>{model.discount ? `${Number(model.discount).toFixed(2)}%` : '-'}</td>
                    <td>{formatMoney(model.extended_price)}</td>
                    {customFieldKeys.map((field) => {
                      const fieldData = model.parsedFields.find((f) => f.label === field);
                      return <td key={field} className="column-custom">
                      {fieldData?.value ?? "-"}
                    </td>;
                    })}
                  </tr>
                ))}
              </tbody>
              </Table>
            <div className="totals-container">
              <div className="totals-card">
                <div className="totals-line">
                  <div>
                    <b>SUB-TOTAL:</b>
                  </div>
                  <div>{formatMoney(orderDetails.subtotal)}</div>
                </div>
                <div className="totals-line">
                  <div>
                    <b>DESCUENTOS:</b>
                  </div>
                  <div>-{formatMoney(orderDetails.discount || 0)}</div>
                </div>
                {taxSettings.tax_rate_2 && (
                  <div className="totals-line">
                    <div>
                      <b>{taxSettings.tax_rate_2}:</b>
                    </div>
                    <div>{formatMoney(calculateTaxRate2())}</div>
                  </div>
                )}
                <div className="totals-line">
                  <div>
                    <b>{taxSettings.tax_rate}:</b>
                  </div>
                  <div>{formatMoney(calculateTaxRate())}</div>
                </div>
                <div className="totals-line">
                  <div>
                    <h4>
                      <b>TOTAL:</b>
                    </h4>
                  </div>
                  <div>{formatMoney(calculateTotal())}</div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsTab;
