import { load, save } from 'react-cookies'
import taxSettings from '../flags_variants/tax_settings.json'
import documentTypes from '../flags_variants/document_types.json'

export const getLocale = () => load('intl_locale') ?? 'en-US';

export const getCurrency = () => load('intl_currency') ?? 'USD';

export const getDiscountPrice = (price, discount) => {
  discount > 0 ? price - price * (discount / 100) : price
}

export const capitalizeFirstLetterFrom = (sentence = '') => {
  if (!sentence) return '';
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

export const capitalizeFirstLetter = (word = '') => word.charAt(0).toUpperCase() + word.slice(1)

export const formatPrice = (price) => `P${price}`

export const formatDate = (date) => {
  const locale = getLocale();
  return new Date(date).toLocaleDateString(locale);
};

export const replaceAccentedCharacters = (text) => {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}

export const formatDiscount = (data, decimals = 5) => {
  const value = parseFloat(data)
  if (value > 100 || value < 0) return 0

  const regex = new RegExp(`(\\.\\d{${decimals}})\\d+`, 'g')
  const validDiscountNumber = data.replace(regex, '$1')
  return parseFloat(validDiscountNumber) || 0
}

export const formatMoney = (amount) => {
  const locale = getLocale();
  const currency = getCurrency();
  const totalAmount = Number(amount).toFixed(2);
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(totalAmount);
};

export const effectiveLocationStr = (effectiveLocation, clientLocation, latitude) => {
  if (
    !clientLocation ||
    Number(clientLocation) === 0 ||
    !latitude ||
    Number(latitude) === 0 ||
    latitude === '0.0'
  ) {
    return 'N/A'
  }
  return effectiveLocation ? 'Sí' : 'No'
}
export const effectiveLocationWithAllowedLocation = (effectiveLocation, hasAllowedLocation) => {
  if (!hasAllowedLocation) return 'N/A'
  if (effectiveLocation) return 'Sí'
  return 'No'
}

export const safePercent = (value, of) => {
  const validValue = Number(value)
  const validOf = Number(of)
  const result = validOf === 0 ? 0 : Math.round((validValue / validOf) * 100)
  return Math.min(result, 100)
}

export const endsVowel = (str) => 'aeiou'.includes(str.slice(-1))

export const setUpTaxSettings = (intlLocale) => {
  const ts =
    intlLocale && taxSettings[intlLocale] ? taxSettings[intlLocale] : taxSettings['default']
  save('tax_settings', ts, { path: '/' })
}

export const parseDocumentType = (documentType) => documentTypes[documentType] || documentType

export const replaceWithBr = (str) => str.replace(/\n/g, '<br />')

export const clearCoordinates = (coord) => {
  if (!coord) {
    return null
  }
  let newCoord = parseFloat(coord)
  if (isNaN(newCoord)) {
    return null
  }
  if (newCoord === 0) {
    return null
  }
  return coord
}

export const getLastActivityTime = (data) => {
  if (!data.length || !data[0].created_date) {
    return '-'
  }

  const lastActivityDate = new Date(data[0].created_date)

  if (isNaN(lastActivityDate)) {
    return '-'
  }

  let hours = lastActivityDate.getHours()
  const minutes = String(lastActivityDate.getMinutes()).padStart(2, '0')
  const suffix = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12 || 12

  return `${hours}:${minutes} ${suffix}`
}

export const assignClientIndicesAndFilter = (data) => {
  let currentIndex = 0
  let lastClientDbRef = null
  const processedData = data
    .reduceRight((resultArray, currentItem) => {
      if (currentItem.client_db_ref !== lastClientDbRef) {
        lastClientDbRef = currentItem.client_db_ref
        currentIndex++
      }
      if (
        currentItem.latitude &&
        currentItem.longitude &&
        currentItem.latitude !== '0.0' &&
        currentItem.longitude !== '0.0'
      ) {
        resultArray.push({
          ...currentItem,
          client_index: currentIndex
        })
      } else {
        resultArray.push({
          ...currentItem,
          client_index: currentIndex
        })
      }
      return resultArray
    }, [])
    .reverse()

  return processedData
}

export const allCoordinatesValid = (data) => {
  return data.every((item) => {
    const lat = item.latitude
    const long = item.longitude
    return (
      (lat === '0.0' && long === '0.0') ||
      (lat === 0 && long === 0) ||
      lat === null ||
      long === null
    )
  })
}

export const allCoordinatesValidClient = (item) => {
  const lat = item.client_latitude
  const long = item.client_longitude
  return (lat === '0.0000000000' && long === '0.0000000000') || lat === null || long === null
}

export const formatDateNoHours = (dateString) => {
  const locale = getLocale();
  const date = new Date(`${dateString}T00:00:00`);
  if (isNaN(date)) {
    return dateString;
  }
  const options = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  };
  return date.toLocaleDateString(locale, options);
};

export const convertByteToMb = (imgBytes) => {
  const mb = imgBytes ? (Number(imgBytes) / 1024 / 1024).toFixed(1) : '0.0'
  return `${mb}MB`
}

export const calculateTaxRate = () => {
  let total = 0
  this.state.activity.details.map(
    (obj) => (total += (parseFloat(obj.extended_price) * parseFloat(obj.tax_rate)) / 100)
  )
  return total
}

export const calculateTotals = (cashData) => {
  return Object.keys(cashData)
    .sort((a, b) => parseFloat(b) - parseFloat(a)) 
    .map((denomination) => {
      const quantity = cashData[denomination] || 0;
      const total = quantity * parseFloat(denomination);
      return {
        denomination: parseFloat(denomination),
        quantity,
        total,
      };
    });
};