import React, { useState, useContext, useEffect, useCallback } from 'react'
import cookie from 'react-cookies'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import PromoIndexView from './PromoIndexView'
import fetchWithAuth from '../helpers/fetchWithAuth'
import usePagination from '../layout/usePagination'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'

const PromoIndexApp = (props) => {
  const [promoData, setPromoData] = useState(null)
  const [filterData, setFilterData] = useState(null)
  const [promoProductsData, setPromoProductsData] = useState(null)
  const [filterQuery, setFilterQuery] = useState(null)
  const [searchQuery, setSearchQuery] = useState(null)
  const [dateQuery, setDateQuery] = useState(null)
  const [title, setTitle] = useState('Promociones')
  const [isFetching, setIsFetching] = useState(true)
  const [isFetchingFilter, setIsFetchingFilter] = useState(true)
  const { pagination, setPagination, handlePaginationClick } = usePagination()
  const [collapsed, setCollapsed] = useState(false)
  const [clientQualifierDic, setClientQualifierDic] = useState([
    { key: 'Cliente individual', value: [] },
    { key: 'Atributo A', value: [] },
    { key: 'Atributo B', value: [] },
    { key: 'Atributo C', value: [] }
  ])

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  const abortCurrentFetchs = () => {
    abortController.abort()
  }
  window.addEventListener('beforeunload', abortCurrentFetchs)
  //////

  const handleToggleCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed)
    localStorage.setItem('collapsedState', newCollapsed)
  }

  useEffect(() => {
    const collapsedFromStorage = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedFromStorage)
  }, [])

  useEffect(() => {
    async function fetchAll() {
      await fetchFilterInfoFromServer()
    }
    fetchAll()
  }, [])

  useEffect(() => {
    const fetchAll = async () => {
      await fetchDataFromServer()
    }

    fetchAll()
  }, [pagination.current_page, filterQuery, searchQuery, dateQuery])

  const updateURLPaginationData = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const currentPageFromURL = searchParams.get('page')

    if (currentPageFromURL !== pagination.current_page.toString()) {
      searchParams.set('page', pagination.current_page)
      let newRelativePathQuery = `${window.location.pathname}?${searchParams}`
      window.history.replaceState(null, '', newRelativePathQuery)
    }
  }

  const fetchDataFromServer = useCallback(async () => {
    setIsFetching(true)
    setPromoData([])

    const per_page = pagination.per_page
    const page = pagination.current_page

    const response = await fetch(
      `${BACKEND_URL}/businesses/${cookie.load('business_id')}/promos?page=${page}&per_page=${per_page}${filterQuery || dateQuery || searchQuery ? '&filter_search=true' : ''}${filterQuery ? filterQuery : ''}${dateQuery ? dateQuery : ''}${searchQuery ? searchQuery : ''}`,
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (response.status === 401) {
      return console.error(response)
    }
    const data = await response.json()

    setPagination((prev) => ({
      ...prev,
      total_pages: Math.ceil(data['count'] / prev.per_page)
    }))
    setPromoData(data['table'])
    setIsFetching(false)
  }, [pagination.current_page, filterQuery, searchQuery, dateQuery])

  const fetchFilterInfoFromServer = async (loadingState = true) => {
    if (loadingState) {
      setIsFetchingFilter(true)
    }
    const response = await fetchWithAuth(
      `${BACKEND_URL}/businesses/${cookie.load('business_id')}/promos?filter_info=true`,
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (response.status === 401) {
      return console.error(response)
    }
    const data = response
    setFilterData(data)

    let products = data['products']
    let clients = data['clients']

    setClientQualifierDic([
      { key: 'Cliente individual', value: clients?.map((c) => c.name) },
      { key: 'Atributo A', value: data['qualifier_2'] },
      { key: 'Atributo B', value: data['qualifier_3'] },
      { key: 'Atributo C', value: data['qualifier_4'] }
    ])
    setPromoProductsData(products?.map((p) => p.name))
    setIsFetchingFilter(false)
  }

  return (
    <div>
      <div>
        <div>
          <TopBar
            searchPlaceholder={''}
            userName={'soporte'}
            onToggleCollapse={handleToggleCollapse}
            collapsed={collapsed}
          />
        </div>
        <div onClick={handlePaginationClick}>
          <PromoIndexView
            promoData={promoData}
            filterData={filterData}
            promoProductsData={promoProductsData}
            pagination={pagination}
            handlePaginationClick={handlePaginationClick}
            dateQuery={dateQuery}
            searchQuery={searchQuery}
            filterQuery={filterQuery}
            setDateQuery={setDateQuery}
            setSearchQuery={setSearchQuery}
            setFilterQuery={setFilterQuery}
            isFetching={isFetching}
            isFetchingFilter={isFetchingFilter}
            title={title}
            successCreateForNewPromo={props.successCreateForNewPromo}
            collapsed={collapsed}
            clientQualifierDic={clientQualifierDic}
            setPagination={setPagination}
          />
        </div>
      </div>
      <div>
        <NavBar activeItem="Promociones" isEditing={false} collapsed={collapsed} />
      </div>
    </div>
  )
}
export default PromoIndexApp
