import { useState } from 'react'

const usePagination = (initialPagination = { per_page: 25, current_page: 1, total_pages: 1 }) => {
  const [pagination, setPagination] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search)
    let page = parseInt(searchParams.get('page')) || 1

    return { ...initialPagination, current_page: page }
  })

  const handlePaginationClick = (event) => {
    event.preventDefault()
    let target = event.target

    if (target.tagName !== 'A') {
      target = target.closest('a')
    }

    if (target && target.id) {
      handlePagination(target.id)
    }
  }

  const handlePagination = (targetId) => {
    setPagination((prevPagination) => {
      let newPage = prevPagination.current_page

      switch (targetId) {
        case 'first-page':
          newPage = 1
          break
        case 'last-page':
          newPage = prevPagination.total_pages
          break
        case 'previous-page':
          if (prevPagination.current_page > 1) {
            newPage = prevPagination.current_page - 1
          }
          break
        case 'next-page':
          if (prevPagination.current_page < prevPagination.total_pages) {
            newPage = prevPagination.current_page + 1
          }
          break
        default:
          return prevPagination
      }

      return { ...prevPagination, current_page: newPage }
    })
  }

  return {
    pagination,
    setPagination,
    handlePaginationClick,
    handlePagination
  }
}

export default usePagination
