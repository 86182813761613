import React, { Component } from 'react'
import TabWithDropdown from '../../layout/TabWithDropdown'
import RefundDetailsTab from './RefundDetailsTab'

export default class ShowRefundView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSuccessfulMessage: false,
      alertState: 'alert alert-success',
      createText: 'Guardar',
      isPosting: false,
      activity: props.defaultDetails
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultDetails !== this.props.defaultDetails) {
      this.setState({ activity: this.props.defaultDetails })
    }
  }

  componentDidMount() {
    this.showSuccessfulMessageCreation()
  }

  showSuccessfulMessageCreation = () => {
    this.setState({
      showSuccessfulMessage: document.referrer.includes('nuevo_pedido')
    })
  }

  hiddenAlert = () => {
    this.setState({ alertState: 'alert alert-success hidden' })
  }

  disableButton = () => {
    this.setState({ createText: 'Guardando...', isPosting: true })
  }

  enableButton = () => {
    this.setState({ createText: 'Guardar', isPosting: false })
  }

  isDelivery = () => {
    const refundType = this.state.activity?.refund_type
    return refundType && refundType !== 'salesman_refund'
  }

  handleRefundType = () => {
    const refundType = this.state.activity?.refund_type
    switch (refundType) {
      case 'refund':
        return 'Rechazo total'
      case 'partial_refund':
        return 'Rechazo parcial'
      default:
        return 'Devolución'
    }
  }

  getDisplayStatus = () => {
    const { refund_type: refundType, status } = this.state.activity || {}
  
    if (refundType === 'refund') {
      return <span className="order-status delivery-received-style">RECHAZADO</span>
    } else if (refundType === 'partial_refund') {
      return <span className="order-status delivery-received-style">PARCIAL</span>
    } else if (status === 'received') {
      return <span className="order-status received-style">RECIBIDO</span>
    } else if (status === 'sent') {
      return <span className="order-status sent-style">ENVIADO</span>
    } else if (status === 'dispatched') {
      return <span className="order-status dispatched-style">DESPACHADO</span>
    } else if (status === 'invoiced') {
      return <span className="order-status billed-style">FACTURADO</span>
    }
  
    return null
  }

  render() {
    const { activity } = this.state

    return (
      <div>
        <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
          <div className="main-show-header">
            <h2>
              {this.handleRefundType()}: {activity?.refund_db_ref}
            </h2>
          </div>
          <div className="index-table">
            <div className="show-area">
              <div>
                <div className="status-orders">
                  <b className="order-status-b">Estado:</b>
                  {this.getDisplayStatus()}
                </div>
                <div>
                  {(activity?.refund_type === 'refund' ||
                    activity?.refund_type === 'partial_refund') && (
                    <ul className="nav nav-tabs">
                      <TabWithDropdown name="Entrega" activeTab={'Entrega'} items={[]} />
                    </ul>
                  )}
                </div>
                <div>
                  {activity && (
                    <RefundDetailsTab
                      activity={activity}
                      transactionUuid={this.props.details.uuid}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
