import React, { useState, useContext, useEffect } from 'react'
import cookie from 'react-cookies'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import PosTaskEffectivityView from './PosTaskEffectivityView'
import PosTaskEffectivityContext from './context/PosTaskEffectivityContext'
import fetchWithAuth from '../helpers/fetchWithAuth'
import usePagination from '../layout/usePagination'

const USE_BUSINESS_URL = process.env.REACT_APP_USE_BUSINESS_URL === 'true'
const BACKEND_URL = USE_BUSINESS_URL
  ? `${process.env.REACT_APP_BACKEND_URL}/businesses/${cookie.load('business_id')}`
  : process.env.REACT_APP_BACKEND_URL

const PosTaskEffectivityApp = () => {
  const [collapsed, setCollapsed] = useState(false)
  const [clientsData, setClientsData] = useState([])
  const [tasksData, setTasksData] = useState([])
  const [fetchDataStatus, setFetchDataStatus] = useState({})
  const [filterQuery, setFilterQuery] = useState(null)
  const [searchQuery, setSearchQuery] = useState(null)
  const [dateQuery, setDateQuery] = useState(null)
  const { pagination, setPagination, handlePaginationClick } = usePagination()
  const [title, setTitle] = useState('Efectividad en punto de venta')

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  const abortCurrentFetchs = () => {
    abortController.abort()
  }
  window.addEventListener('beforeunload', abortCurrentFetchs)
  //////

  const handleToggleCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed)
    localStorage.setItem('collapsedState', newCollapsed)
  }

  useEffect(() => {
    const collapsedFromStorage = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedFromStorage)
  }, [])

  useEffect(() => {
    async function fetchAll() {
      await fetchClientsFromServer()
    }
    fetchAll()
  }, [])

  useEffect(() => {
    updateURLPaginationData()
    async function fetchAll() {
      await fetchTasksFromServer()
    }
    fetchAll()
  }, [pagination.current_page, filterQuery, searchQuery, dateQuery])

  const updateURLPaginationData = () => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('page', pagination.current_page)

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`
    window.history.replaceState(null, '', newUrl)
  }

  const fetchClientsFromServer = async (loadingState = true) => {
    if (loadingState) {
      setFetchDataStatus((prev) => ({ ...prev, filters: true }))
    }

    const response = await fetchWithAuth(
      `${BACKEND_URL}/pos_task_activities?client_names_only=true&business_tenant_uid=${cookie.load('business_tenant_uid')}`,
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (!response || response.error) {
      return console.error(
        `Error in fetchFilterInfoFromServer: ${response ? response.status : 'Fetch error'}`
      )
    }
    setClientsData(response.result)
    setFetchDataStatus((prev) => ({ ...prev, filters: false }))
  }

  const fetchTasksFromServer = async () => {
    const per_page = pagination.per_page
    const page = pagination.current_page

    const response = await fetch(
      `${BACKEND_URL}/pos_task_activities?page=${page}&per_page=${per_page}${
        filterQuery || dateQuery || searchQuery ? '&filter_search=true' : ''
      }${filterQuery || ''}${dateQuery || ''}${searchQuery || ''}&business_tenant_uid=${cookie.load('business_tenant_uid')}`,
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )

    if (!response.ok) {
      return console.error(response)
    }

    const data = await response.json()
    setPagination((prev) => ({ ...prev, total_pages: Math.ceil(data.count / per_page) }))
    setTasksData(data.result)
  }

  return (
    <div>
      <div>
        <div>
          <TopBar
            searchPlaceholder={''}
            userName={'soporte'}
            onToggleCollapse={handleToggleCollapse}
            collapsed={collapsed}
          />
        </div>
        <div>
          <PosTaskEffectivityContext.Provider
            value={{
              fetchDataStatus,
              clientsData,
              tasksData,
              pagination,
              setFilterQuery,
              setDateQuery,
              setSearchQuery,
              filterQuery,
              dateQuery,
              searchQuery,
              fetchClientsFromServer,
              fetchTasksFromServer,
              handlePaginationClick,
              title,
              setPagination
            }}
          >
            <PosTaskEffectivityView collapsed={collapsed} />
          </PosTaskEffectivityContext.Provider>
        </div>
      </div>
      <div>
        <NavBar activeItem="Ejecución punto de venta" isEditing={false} collapsed={collapsed} />
      </div>
    </div>
  )
}

export default PosTaskEffectivityApp
