import React, { useState, useEffect } from 'react'
import cookie from 'react-cookies'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import ClientFormTextQuestion from './components/ClientFormTextQuestion'
import ClientFormListQuestion from './components/ClientFormListQuestion'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faLock } from '@fortawesome/free-solid-svg-icons'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const ClientApplicationForm = () => {
  const [saving, setSaving] = useState(false)
  const [readyToCollect, setReadyToCollect] = useState(false)
  const [failedCreation, setFailedCreation] = useState(false)
  const [formData, setformData] = useState(null)
  const [cloningTask, setCloningTask] = useState(false)
  const [isRequired, setIsRequired] = useState(false)
  const [collapsed, setCollapsed] = useState(false)
  const [description, setDescription] = useState('')

  const [selectedOption, setSelectedOption] = useState('')
  const [questionInstances, setQuestionInstances] = useState([])
  const [isSelectionValid, setIsSelectionValid] = useState(true)
  const MAX_QUESTION_LIMIT = 50
  const [isQuestionLimitReached, setIsQuestionLimitReached] = useState(false)

  const urlParams = new URLSearchParams(window.location.search)

  const generateOptions = (type) => {
    return [
      {
        id: 1,
        text: `Opción ${type == 'multiplechk' ? `múltiple 1` : ''}`,
        selected: false,
        editable: true
      },
      {
        id: 2,
        text: `Opción ${type == 'multiplechk' ? `múltiple 2` : ''}`,
        selected: false,
        editable: true
      }
    ]
  }

  const questionInstancesKeys = Object.keys(questionInstances)

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  const abortCurrentFetchs = () => {
    abortController.abort()
  }
  window.addEventListener('beforeunload', abortCurrentFetchs)
  //////

  const sendForm = (event) => {
    event.preventDefault()
    setFailedCreation(false)
    setReadyToCollect(true)
    setIsRequired(false)
  }

  const formDataCollector = (data) => {
    let finalData = {
      ...data,
      description: description,
      business_uid: cookie.load('business_tenant_uid'),
      business_id: cookie.load('business_id')
    }
    postFormToBackend(finalData)
    setReadyToCollect(false)
  }

  const postFormToBackend = (body) => {
    setSaving(true)
    fetch(`${BACKEND_URL}/businesses/${cookie.load('business_id')}/clients_applications`, {
      method: 'POST',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify(body)
    })
      .then((r) => evaluateResponse(r))
      .catch((e) => console.log(e))
  }

  const startCloning = async () => {
    let formToClone = await getFormToClone()
    if (formToClone) {
      setSaving(true)
      setformData(formToClone)
    }
  }

  const getFormToClone = async () => {
    const response = await fetch(
      `${BACKEND_URL}/businesses/${cookie.load('business_id')}/clients_applications`,
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (!response.ok) {
      setSaving(false)
      return console.error(`Error coping form in ClientApplicationForm.js: ${response.status}`)
    }
    const data = await response.json()
    return data[0]
  }

  const setQueryParams = () => {
    const uuidToClone = urlParams.get('update') || ''
    if (uuidToClone) {
      startCloning(uuidToClone)
    }
  }

  useEffect(() => {
    setQueryParams()
  }, [])

  const evaluateResponse = async (response) => {
    // navigate to task details
    switch (response.status) {
      case 422:
      case 409:
        setFailedCreation('Error al crear solicitud de clientes nuevos.')
        setSaving(false)
        break
      case 404:
      case 400:
        setFailedCreation('Servidor fuera de linea. Intente más tarde')
        setSaving(false)
        break
      case 500:
        setFailedCreation('Error interno del sistema. Intente más tarde')
        setSaving(false)
        break
      default:
        let data = await response.json()
        setFailedCreation(false)
        // redirect to task details
        window.location = `/solicitud_cliente_nuevo?success=true`
        break
    }
  }

  const cancelCreate = (event) => {
    event.preventDefault()
    const updating = urlParams.get('update') || false
    const val = window.confirm(
      '¿Esta seguro que quiere continuar? Los cambios editados no seran guardados'
    )
    if (val && updating) {
      window.location = `/solicitud_cliente_nuevo`
    } else if (val) {
      window.location = `/productos`
    }
  }

  const handleToggleCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed)
    localStorage.setItem('collapsedState', newCollapsed)
  }

  useEffect(() => {
    const collapsedFromStorage = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedFromStorage)
  }, [])

  const questionSelectionType = [
    { value: '', text: 'Escoja una opción', hidden: true },
    { value: 'text', text: 'Texto (Pregunta abierta)' },
    { value: 'number', text: 'Solo números' },
    { value: 'multiplerb', text: 'Opción única' },
    { value: 'multiplechk', text: 'Opción múltiple' },
    { value: 'image', text: 'Subir imagen' },
    { value: 'geolocation', text: 'Geolocalización' }
  ]

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  }

  const handleOptionValid = (event) => {
    const selectedValue = event.target.value
    setSelectedOption(selectedValue)
    setIsSelectionValid(selectedValue !== '')
  }

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })

  const collectData = () => {
    const instances = Object.values(questionInstances)
    let jsonData = {
      question_quantity: instances.length,
      questions: instances.map((instance, index) => ({
        uid: crypto.randomUUID(),
        type: instance.type,
        id: index,
        type: instance.type,
        question: instance.question,
        options: instance.options
      }))
    }
    formDataCollector(jsonData)
  }

  const setUpClientForm = async () => {
    if (formData) {
      setCloningTask(true)
      setDescription(formData.description)
      if (formData.client_form?.length > 0) {
        setIsRequired(false)
        const clonedQuestionInstances = formData.client_form.map((questionInstance) => {
          return { ...questionInstance }
        })
        setQuestionInstances(clonedQuestionInstances)
      }
      setCloningTask(false)
      await sleep(1000)
      setSaving(false)
    }
  }

  const handleUpdateQuestion = (id, question, value) => {
    setQuestionInstances((prevInstances) => {
      if (prevInstances[id]?.[question] === value) {
        return prevInstances
      }
      return {
        ...prevInstances,
        [id]: {
          ...prevInstances[id],
          [question]: value
        }
      }
    })
  }

  const handleClickSelectForm = () => {
    const isSelectionEmpty = selectedOption.trim().length === 0
    setIsRequired(isSelectionEmpty)

    if (!isSelectionValid || isSelectionEmpty) {
      setTimeout(() => {
        setIsRequired(false)
      }, 2000)
      return
    }

    setQuestionInstances((prevInstances) => {
      const existingIds = Object.keys(prevInstances).map(Number)
      const newInstanceId = existingIds.length > 0 ? Math.max(...existingIds) + 1 : 1

      return {
        ...prevInstances,
        [newInstanceId]: {
          type: selectedOption,
          options:
            selectedOption == 'multiplerb' || selectedOption == 'multiplechk'
              ? generateOptions(selectedOption)
              : ''
        }
      }
    })

    setSelectedOption('')
  }

  const duplicateQuestion = (instanceId, duplicatedData, previousData) => {
    setQuestionInstances((prevInstances) => {
      const existingIds = Object.keys(prevInstances).map(Number)
      const newId = existingIds.length > 0 ? Math.max(...existingIds) + 1 : 1

      return {
        ...prevInstances,
        [newId]: { ...previousData, ...duplicatedData }
      }
    })
  }

  const deleteQuestion = (id) => {
    setQuestionInstances((prevInstances) => {
      const { [id]: deletedInstance, ...restInstances } = prevInstances
      return restInstances
    })
  }

  const isDescriptionWritten = () => {
    return description.length > 0
  }

  useEffect(() => {
    setIsQuestionLimitReached(questionInstancesKeys.length >= MAX_QUESTION_LIMIT)
  }, [questionInstancesKeys])

  useEffect(() => {
    if (formData) {
      setUpClientForm()
    }
  }, [formData])

  useEffect(() => {
    const isEmptyQuestionInstances = Object.keys(questionInstances).length === 0
    setIsRequired(isEmptyQuestionInstances ? true : isRequired)
  }, [questionInstances, isRequired])

  useEffect(() => {
    if (readyToCollect) {
      collectData()
    }
  }, [readyToCollect])

  const onDragEnd = (result) => {
    if (!result.destination || result.source.index === result.destination.index) return

    setQuestionInstances((prevInstances) => {
      const items = Object.entries(prevInstances)
      const [reorderedItem] = items.splice(result.source.index, 1)
      items.splice(result.destination.index, 0, reorderedItem)

      const newQuestionInstances = {}
      items.forEach(([key, value], index) => {
        newQuestionInstances[index + 1] = value
      })

      return newQuestionInstances
    })
  }

  useEffect(() => {}, [questionInstances])

  return (
    <div>
      <div>
        <div>
          <TopBar
            searchPlaceholder={''}
            onToggleCollapse={handleToggleCollapse}
            collapsed={collapsed}
          />
        </div>
        <div>
          <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
            {failedCreation && (
              <div className="alert alert-danger" role="alert">
                <strong>{failedCreation}</strong>
              </div>
            )}

            <div className="show-title">
              <h2>Solicitud de cliente nuevo</h2>
            </div>
            <form onSubmit={sendForm}>
              <div className="index-table">
                <div className="show-area">
                  <div className="create-promo-wrapper">
                    <div className="inline-block full-width">
                      <div className="float-left">
                        <h5>Ingrese los siguientes datos para configurar solicitud</h5>
                      </div>
                      <div className="float-right">
                        <button disabled={saving} onClick={cancelCreate} className="cancel-button">
                          Cancelar
                        </button>
                        <input
                          disabled={saving || !isDescriptionWritten}
                          className="save-button"
                          value={saving ? 'Guardando...' : 'Guardar'}
                          type="submit"
                        />
                      </div>
                    </div>
                    <hr />
                    {cloningTask && (
                      <div className="promo-form-section">
                        <div className="title-promos-form">
                          <b>Cargando tarea...</b>
                        </div>
                      </div>
                    )}
                    {!cloningTask && (
                      <>
                        <div className="instructions-block">
                          <FontAwesomeIcon icon={faExclamationCircle} style={{ marginRight: 10 }} />
                          <div>
                            <span className="instructions-highlight">Instrucciones:</span> Crea las
                            preguntas necesarias para capturar toda la información requerida de un
                            nuevo cliente, eligiendo el tipo de pregunta que mejor se adapte al dato
                            requerido. Una vez configurado, podrás editarlo o ajustarlo según sea
                            necesario.
                          </div>
                        </div>
                        <div className="promo-form-section">
                          <div className="title-promos-form">
                            <b>
                              PASO 1: Escriba una breve descripción que será visible para sus
                              colaboradores en la aplicación
                            </b>
                          </div>
                          <hr className="hr-promos-form" />
                          <div className="form-group form-control-width">
                            <input
                              type="text"
                              maxLength="100"
                              className="form-control"
                              id="description"
                              name="description"
                              onChange={handleDescriptionChange}
                              value={description}
                              placeholder="Ejemplo: Complete la siguiente solicitud para registrar los datos del cliente."
                              required
                            />
                            <p className="small text-muted mt-10 pull-right">
                              Cantidad máxima de caracteres {Math.abs(description.length - 100)}/100
                            </p>
                          </div>
                        </div>
                        <div>
                          <div className="promo-form-section mt-25">
                            <div className="title-promos-form">
                              <b>PASO 2: Crear la solicitud</b>
                            </div>
                            <hr className="hr-promos-form" />
                          </div>
                          <div className="notice-text mt-10">
                            <b>
                              Aviso: El campo 'Nombre del cliente' es obligatorio y está bloqueado.
                              No es posible editarlo o moverlo.
                            </b>
                          </div>
                          <div className="survey-form-sections">
                            <div className={`survey-text-task-container`}>
                              <div className={`headers`}>
                                <div className="question-text">
                                  <p>Pregunta 1:</p>
                                </div>
                                <div>
                                  <FontAwesomeIcon icon={faLock} className="lock-container" />
                                </div>
                              </div>
                              <div className={`form-group disabled-border-input`}>
                                <textarea
                                  className={`form-control  inactive`}
                                  name="clientName"
                                  placeholder="Nombre del cliente"
                                  rows={1}
                                  maxLength="250"
                                  disabled
                                />
                              </div>
                              <div className={`form-group`}>
                                <input
                                  disabled
                                  type="text"
                                  className={`form-control inactive`}
                                  placeholder="Respuesta de texto, abierta sin respuestas predefinidas"
                                />
                              </div>
                            </div>
                          </div>
                          <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                              {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                  {Object.keys(questionInstances).map((instanceId, index) => (
                                    <Draggable
                                      key={instanceId}
                                      draggableId={instanceId}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <div className="survey-form-sections">
                                          {(questionInstances[instanceId].type === 'text' ||
                                            questionInstances[instanceId].type === 'number' ||
                                            questionInstances[instanceId].type === 'image' ||
                                            questionInstances[instanceId].type ===
                                              'geolocation') && (
                                            <ClientFormTextQuestion
                                              id={instanceId}
                                              data={questionInstances[instanceId]}
                                              onDuplicate={(duplicatedData) =>
                                                duplicateQuestion(
                                                  instanceId,
                                                  duplicatedData,
                                                  questionInstances[instanceId]
                                                )
                                              }
                                              onDelete={() => deleteQuestion(instanceId)}
                                              onUpdateQuestion={handleUpdateQuestion}
                                              questionNumber={index + 2}
                                              isCreatingForm={true}
                                              provided={provided}
                                              index={index}
                                              questionType={questionInstances[instanceId].type}
                                            />
                                          )}

                                          {(questionInstances[instanceId].type === 'multiplechk' ||
                                            questionInstances[instanceId].type ===
                                              'multiplerb') && (
                                            <ClientFormListQuestion
                                              id={instanceId}
                                              data={questionInstances[instanceId]}
                                              onDuplicate={(duplicatedData) =>
                                                duplicateQuestion(
                                                  instanceId,
                                                  duplicatedData,
                                                  questionInstances[instanceId]
                                                )
                                              }
                                              onDelete={() => deleteQuestion(instanceId)}
                                              onUpdateQuestion={handleUpdateQuestion}
                                              questionNumber={index + 2}
                                              showOptionsButton={true}
                                              isCreatingForm={true}
                                              provided={provided}
                                              index={index}
                                              questionType={questionInstances[instanceId].type}
                                            />
                                          )}
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                          <div className="survey-form-sections">
                            <p>Nueva pregunta</p>
                            <div className="form-control-widths">
                              <select
                                className={`select-promo-type form-control custom-select form-control-widths ${
                                  !isDescriptionWritten() && 'inactive'
                                }`}
                                name="new"
                                onChange={handleOptionValid}
                                value={selectedOption}
                                disabled={!isDescriptionWritten()}
                                required={isRequired}
                              >
                                {questionSelectionType.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                    hidden={option.hidden}
                                  >
                                    {option.text}
                                  </option>
                                ))}
                              </select>
                              <button
                                className={`btnsurvey ${!isDescriptionWritten() && 'inactive'}`}
                                disabled={
                                  !isDescriptionWritten() ||
                                  !isSelectionValid ||
                                  isQuestionLimitReached
                                }
                                onClick={handleClickSelectForm}
                                type="button"
                                noValidate
                              >
                                <span className="icon-survey-add">+</span>{' '}
                                <span className="select-add-question">Agregar</span>
                              </button>
                            </div>
                            {isQuestionLimitReached && (
                              <p className="warning-text">
                                Ha alcanzado el límite máximo de 50 preguntas permitidas. No es
                                posible agregar más preguntas.
                              </p>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        <NavBar activeItem="Clientes" isEditing={false} collapsed={collapsed} />
      </div>
    </div>
  )
}
export default ClientApplicationForm
