import React, { useState, useEffect, useRef } from 'react'
import { FixedSizeList as List } from 'react-window'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { replaceAccentedCharacters } from '../../helpers/utils'

export default function ReactFilterList({
  title,
  options,
  identifier,
  resetWatcher,
  isRequired,
  onSelectionChange,
  disabled,
  selectAllRequired
}) {
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState(new Set())
  const [selectAll, setSelectAll] = useState(false)
  const [search, setSearch] = useState('')
  const filterContainer = useRef(null)

  const MIN_ITEMS = 4
  const MAX_ITEMS = 6
  const ITEM_HEIGHT = 45
  const ITEM_HEIGHT_SINGLE = 80
  const BASE_HEIGHT = 60

  const calculateMaxHeight = () => {
    const numberOfOptions = filteredOptions.length

    switch (true) {
      case identifier == 'Cumple Tarea':
        return 170
      case numberOfOptions === 1:
        return BASE_HEIGHT + ITEM_HEIGHT_SINGLE
      case numberOfOptions <= MIN_ITEMS:
      case numberOfOptions <= MAX_ITEMS:
        return BASE_HEIGHT + numberOfOptions * ITEM_HEIGHT
      default:
        return 260
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => document.removeEventListener('mousedown', handleOutsideClick)
  }, [])

  useEffect(() => {
    if (disabled) {
      setDropdownOpen(false)
    }
  }, [disabled])

  useEffect(() => {
    // Reset states when resetWatcher changes
    setSelectedOptions(new Set())
    setSelectAll(false)
    setSearch('')
  }, [resetWatcher])

  const handleOutsideClick = (e) => {
    if (filterContainer.current && !filterContainer.current.contains(e.target)) {
      setDropdownOpen(false)
    }
  }

  const toggleSelectAll = () => {
    if (!selectAll) {
      const newSelectedOptions = new Set(filteredOptions)
      setSelectedOptions(newSelectedOptions)
      onSelectionChange(identifier, newSelectedOptions)
    } else {
      setSelectedOptions(new Set())
      onSelectionChange(identifier, new Set())
    }
    setSelectAll(!selectAll)
  }

  const filterOptions = () => {
    return options?.filter((option) => shouldDisplay(option))
  }
  const shouldDisplay = (option) => {
    if (search.length <= 0) return true
    return replaceAccentedCharacters(option).includes(replaceAccentedCharacters(search))
  }

  const selectOption = (option) => {
    if (disabled) return
    const newSelectedOptions = new Set(selectedOptions)
    if (newSelectedOptions.has(option)) {
      newSelectedOptions.delete(option)
    } else {
      newSelectedOptions.add(option)
    }
    setSelectedOptions(newSelectedOptions)

    onSelectionChange(identifier, newSelectedOptions)
  }

  const handleFilterSearch = (e) => {
    if (disabled) return
    setSearch(e.target.value)
    setSelectAll(false)
  }

  const filteredOptions = options.filter(
    (option) =>
      option != null &&
      replaceAccentedCharacters(option.toString()).includes(replaceAccentedCharacters(search))
  )

  const Row = ({ index, style }) => {
    const option = filteredOptions[index]
    return (
      <div style={style} className="react-filter-option" onClick={() => selectOption(option)}>
        <input
          type="checkbox"
          className="filter-checkbox"
          readOnly
          checked={selectedOptions.has(option)}
          disabled={disabled}
        />
        <div className="filter-checkbox-label">{option}</div>
      </div>
    )
  }

  return (
    <div
      className={`react-filter-box ${disabled ? 'react-filter-disabled' : ''}`}
      ref={filterContainer}
    >
      <div
        title={title}
        onClick={() => !disabled && setDropdownOpen(!isDropdownOpen)}
        className="react-filter-box-clickable-zone"
      >
        <div className="react-filter-titles">
          <div className="filter-title-overflow">{title.toUpperCase()}</div>
          {isRequired && selectedOptions.size == 0 && <span className="text-red">*</span>}
          {selectedOptions.size > 0 && (
            <span className="text-orange">({selectedOptions.size})</span>
          )}
          <FontAwesomeIcon icon={!isDropdownOpen ? faChevronDown : faChevronUp} />
        </div>
      </div>
      {isDropdownOpen && (
        <div
          className="react-filter-dropdown-box box-transition"
          style={{
            maxHeight: `${calculateMaxHeight()}px`
          }}
        >
          <div className="filter-search-input-container">
            <input
              type="search"
              className="filter-search-input"
              placeholder="Palabras claves..."
              value={search}
              onChange={handleFilterSearch}
              disabled={disabled}
            />
            {search && (
              <div
                title="Limpiar texto"
                onClick={() => setSearch('')}
                className="filter-search-input-x"
              >
                X
              </div>
            )}

            <div className="react-filter-dropdown-box-items">
              {(filteredOptions.length <= 50 || selectAllRequired) && (
                <div className="react-filter-option" onClick={toggleSelectAll}>
                  <input type="checkbox" className="filter-checkbox" readOnly checked={selectAll} />
                  <div className="filter-checkbox-label mt-10 ml--10">
                    {selectAll ? 'Deseleccionar todos' : 'Seleccionar todos'}
                  </div>
                </div>
              )}

              {filterOptions()?.length == 0 && search && (
                <div className="react-filter-option">
                  <div className="filter-checkbox-label mt-10 ml--10 ">No hubo resultados</div>
                </div>
              )}
            </div>
          </div>
          <List height={300} itemCount={filteredOptions.length} itemSize={35} width="100%">
            {Row}
          </List>
        </div>
      )}
    </div>
  )
}
