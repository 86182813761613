import React from 'react';
import { formatMoney, calculateTotals } from '../../helpers/utils';

const CashCount = ({ cashData, cashDataTotales }) => {
  const diffColor = (reported, total) => {
    if (total > reported) return 'totals-diff-green';
    if (reported > total) return 'totals-diff-red';
    return '';
  };

  const totals = calculateTotals(cashData);

  return (
    <div className="totals-column">
      <div className="totals-container-right">
        <div className="totals-card-left">
          <div className="totals-line">
            <div>
              <b>ARQUEO DE CAJA</b>
            </div>
          </div>
          <div className="totals-line-cash">
            <div className="totals-cash-item">Denominación</div>
            <div className="totals-cash-item-center">Cantidad</div>
            <div className="totals-cash-item-left">Total</div>
          </div>
          {totals.map(({ denomination, quantity, total }) => (
            <div key={denomination} className="totals-line-cash">
              <div className="totals-cash-item">{formatMoney(denomination)}</div>
              <div className="totals-cash-item-center">{quantity}</div>
              <div className="totals-cash-item-left">{formatMoney(total)}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="totals-container-right">
        <div className="totals-card-left">
          <div className="totals-line">
            <div>
              <b className="totals-line-bold-text">Efectivo reportado: </b>
            </div>
            <div>
              <b>{formatMoney(cashDataTotales.reported_cash)}</b>
            </div>
          </div>
          <hr style={{ margin: 0 }} />
          <div className="totals-line">
            <div>
              <b className="totals-line-bold-text">Total arqueo de caja: </b>
            </div>
            <div>
              <b>{formatMoney(cashDataTotales.closure_total)}</b>
            </div>
          </div>
          <hr style={{ margin: 0 }} />
          <div className="totals-line">
            <div>
              <b className="totals-line-bold-text">Diferencia: </b>
            </div>
            <div>
              <b
                className={diffColor(
                  cashDataTotales.reported_cash,
                  cashDataTotales.closure_total
                )}
              >
                {formatMoney(cashDataTotales.cash_difference)}
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashCount;
